import ExpandingCardContent from "../ExpandingCard/ExpandingCardContent";
import ExpandingCard from "../ExpandingCard/ExpandingCard";
import { HiOutlineX, HiSearch } from "react-icons/hi";
import FlexGroup from "../FlexGroup/FlexGroup";
import { useState } from "react";
import "./Search.css";

export default function Search({ placeholder, fontSize, selected, setSelected, searchFunction, expand }) {
	const thisFont = fontSize ? fontSize : "20px";
	const thisPlaceholder = placeholder ? placeholder : "Search...";
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const [opened, setOpened] = useState(false);

	function handleSearch(event) {
		setOpened(true);
		setQuery(event.target.value);
		if (event.target.value) {
			const filtered = searchFunction(event.target.value.toLowerCase());
			setResults(filtered);
		} else {
			searchFunction("");
			setOpened(false);
			setResults([]);
		}
	}

	function handleSelect(id) {
		if (id === selected) {
			setSelected(null);
			return;
		}
		setSelected(id);
	}

	function closeSearch() {
		setOpened(false);
		setQuery("");
		setResults([]);
		searchFunction("");
	}

	return (
		<div className="select-container">
			<FlexGroup gap="10px">
				<ExpandingCard background="var(--Baby)">
					<div className="search-header">
						<input
							className="search-component"
							type="text"
							placeholder={thisPlaceholder}
							style={{ fontSize: thisFont }}
							value={query}
							onChange={handleSearch}
						/>
						{opened ? (
							<HiOutlineX className="search-icon" onClick={closeSearch} style={{ cursor: "pointer" }} />
						) : (
							<HiSearch className="search-icon" />
						)}
					</div>
					{expand && (
						<ExpandingCardContent expanded={opened}>
							<div className="select-option-group">
								{results.map((option, index) => {
									return (
										<div className="select-option" key={index}>
											<h4
												onClick={() => handleSelect(option)}
												style={{
													backgroundColor:
														selected === option ? "var(--Teal)" : "var(--Baby)",
												}}
											>
												{option.name}
											</h4>
										</div>
									);
								})}
							</div>
						</ExpandingCardContent>
					)}
				</ExpandingCard>
			</FlexGroup>
		</div>
	);
}
