import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Spacer from "../Spacer/Spacer";
import "./Error.css";

export default function Error({ dataError, message }) {
	return (
		<MainLayout>
			<Spacer height="1rem" />
			<h3 className="error-message">{dataError ? dataError : message}</h3>
			<button className="error-button" onClick={() => window.location.reload()}>
				Try Again
			</button>
		</MainLayout>
	);
}
