import useCommunityDescriptionHeight from "../../Hooks/CommunityDescriptionHeight";
import { HiOutlineArrowsExpand, HiThumbUp } from "react-icons/hi";
import useToggler from "../../Hooks/ToggleHook";
import { formateDate } from "../../Utils/helpers";
import { HiUserPlus } from "react-icons/hi2";
import ReactQuill from "react-quill";

export default function CommunityPost({ personal, post, index, setupModal, follow, likes, goPersonal, thisRef }) {
	const { showItem, toggleHandler } = useToggler([]);
	const DH = useCommunityDescriptionHeight(showItem, index, post);

	return (
		<div className="community-feed-card" key={index} ref={thisRef}>
			<HiOutlineArrowsExpand className="expand-card" onClick={() => setupModal(post)} />
			{!personal && <h2 onClick={() => goPersonal(post.user.id)}>@{post.user.displayName}</h2>}
			<h3>{post.name}:</h3>
			<div className="quill-wrapper" style={{ maxHeight: DH.descHeight }} ref={DH.quillWrapperRef}>
				<ReactQuill value={post.description} readOnly={true} modules={{ toolbar: false }} />
			</div>
			<h4 className="communtiy-see-more" onClick={() => toggleHandler(index)}>
				{DH.determineExpandMessage()}
			</h4>
			<div className="community-card-footer">
				<div className="community-card-likes">
					{!personal && (
						<HiUserPlus
							onClick={() => follow(post.userFollowsCreator, post.user.id)}
							style={{
								color: `${post.userFollowsCreator ? "var(--Green)" : "var(--Gray)"}`,
								filter: `${post.userFollowsCreator ? "drop-shadow(0px 0px 4px var(--Green))" : "none"}`,
							}}
						/>
					)}
					<HiThumbUp
						onClick={() => likes(post.userHasLiked, post.id, post.user.id)}
						style={{
							color: `${post.userHasLiked ? "var(--Green)" : "var(--Gray)"}`,
							filter: `${post.userHasLiked ? "drop-shadow(0px 0px 4px var(--Green))" : "none"}`,
						}}
					/>
					<p>{post.likes_count}</p>
				</div>
				<p>{formateDate(post.created_at)}</p>
			</div>
		</div>
	);
}
