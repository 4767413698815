import { followUser, likeWorkout, unfollowUser, unlikeWorkout } from "../Api/Community";
import { useMutation } from "react-query";
import { useState } from "react";

export default function useSocial(community, setCommunity, uid, token) {
	const [disableButtons, setDisableButtons] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	/* Follow a user
    ========================================================================*/
	const followSomeUser = useMutation(followUser, {
		onSuccess: (data, variables) => {
			if (data.error) {
				setShowError(true);
				setErrorMessage(data.error);
				setDisableButtons(false);
				return;
			}
			const followState = { followedUserId: variables.followed_id, isFollowing: true };
			updateFollowingInCommunity(followState);
			setDisableButtons(false);
		},
	});

	function executeFollowUser(follower_id, followed_id, token) {
		setDisableButtons(true);
		followSomeUser.mutate({ follower_id, followed_id, token });
	}

	/* Unollow a user
    ========================================================================*/
	const unfollowSomeUser = useMutation(unfollowUser, {
		onSuccess: (data, variables) => {
			if (data.error) {
				setShowError(true);
				setErrorMessage(data.error);
				setDisableButtons(false);
				return;
			}
			const followState = { followedUserId: variables.followed_id, isFollowing: false };
			updateFollowingInCommunity(followState);
			setDisableButtons(false);
		},
	});

	function executeUnfollowUser(follower_id, followed_id, token) {
		setDisableButtons(true);
		unfollowSomeUser.mutate({ follower_id, followed_id, token });
	}

	/* Like a workout
    ========================================================================*/
	const likeSomeWorkout = useMutation(likeWorkout, {
		onSuccess: (data, variables) => {
			if (data.error) {
				setShowError(true);
				setErrorMessage(data.error);
				setDisableButtons(false);
				return;
			}
			const likeState = { postId: variables.workout_id, hasLiked: true, likeChange: 1 };
			updateLikesInCommunity(likeState);
			setDisableButtons(false);
		},
	});

	function executeLikeWorkout(user_id, workout_id, token) {
		setDisableButtons(true);
		likeSomeWorkout.mutate({ user_id, workout_id, token });
	}

	/* Unlike a workout
    ========================================================================*/
	const unlikeSomeWorkout = useMutation(unlikeWorkout, {
		onSuccess: (data, variables) => {
			if (data.error) {
				setShowError(true);
				setErrorMessage(data.error);
				setDisableButtons(false);
				return;
			}
			const likeState = { postId: variables.workout_id, hasLiked: false, likeChange: -1 };
			updateLikesInCommunity(likeState);
			setDisableButtons(false);
		},
	});

	function executeUnlikeWorkout(user_id, workout_id, token) {
		setDisableButtons(true);
		unlikeSomeWorkout.mutate({ user_id, workout_id, token });
	}

	/* Update likes in community array
    ========================================================================*/
	function updateLikesInCommunity(likeState) {
		const updatedCommunity = community.map((post) => {
			if (post.id === likeState.postId) {
				const updatedPost = {
					...post,
					userHasLiked: likeState.hasLiked,
					likes_count: Number(post.likes_count) + likeState.likeChange,
				};
				return updatedPost;
			}
			return post;
		});

		setCommunity(updatedCommunity);
	}

	/* Update following in community array
    ========================================================================*/
	function updateFollowingInCommunity(followState) {
		const updatedCommunity = community.map((post) => {
			if (post.user.id === followState.followedUserId) {
				return {
					...post,
					userFollowsCreator: followState.isFollowing,
				};
			}
			return post;
		});
		setCommunity(updatedCommunity);
	}

	function follow(userFollowsCreator, postUsersId) {
		if (postUsersId === uid || disableButtons) return;
		if (userFollowsCreator) {
			executeUnfollowUser(uid, postUsersId, token);
		} else {
			executeFollowUser(uid, postUsersId, token);
		}
	}

	function likes(userHasLiked, postId, postUsersId) {
		if (postUsersId === uid || disableButtons) return;
		if (userHasLiked) {
			executeUnlikeWorkout(uid, postId, token);
		} else {
			executeLikeWorkout(uid, postId, token);
		}
	}

	return {
		likes,
		follow,
		showError,
		errorMessage,
		setShowError,
	};
}
