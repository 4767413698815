import { Figura, FiguraText, FiguraSubmitBtn, FiguraLabel } from "react-figura";
import { postNewUsername } from "../../Api/Community";
import PostMessage from "../PostMessage/PostMessage";
import FlexGroup from "../FlexGroup/FlexGroup";
import { useMutation } from "react-query";
import Spacer from "../Spacer/Spacer";
import Modal from "../Modal/Modal";

export default function UpdateUsername({ setShowUpdateUsername, uid, token }) {
	const updateUsernameMutation = useMutation(postNewUsername, {
		onSuccess: (data) => {
			if (data.error) return;
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});

	function handleUpdatingUsername({ username }) {
		updateUsernameMutation.mutate({ id: uid, username, token });
	}

	return (
		<Modal setShowModal={setShowUpdateUsername}>
			<h2 className="modal-title">Update Username</h2>
			<Figura formId="update-username" formStyle="profile-figura-form" onSubmit={handleUpdatingUsername}>
				<FiguraText
					name="username"
					inputStyle="figura-input"
					errorStyle="figura-error"
					wrapper="figura-wrapper"
				>
					<FiguraLabel labelStyle="figura-label">New Username</FiguraLabel>
				</FiguraText>
				<FiguraSubmitBtn buttonStyle="figura-submit">Submit</FiguraSubmitBtn>
			</Figura>
			<Spacer height="1rem" />
			<FlexGroup width="100%" center="center">
				<PostMessage
					successMessage="Username updated! Refreshing in 3 seconds..."
					errorMessage="Error updating username. Please try again or check back later."
					status={updateUsernameMutation.status}
					dataError={updateUsernameMutation.data?.error}
				></PostMessage>
			</FlexGroup>
			<Spacer height="1rem" />
		</Modal>
	);
}
