import "./ExpandingCard.css";

export default function ExpandingCard({ children, background, inverted }) {
	const isInverted = inverted ? true : false;
	const thisBackground = background ? background : "var(--Tiffany)";
	const theseColors = {
		background: isInverted ? "var(--Black)" : "",
		text: isInverted ? "var(--Baby)" : "var(--Black)",
		border: isInverted ? "1px solid var(--Baby)" : "none",
	};

	return (
		<div
			className={`expanding-card ${isInverted ? "inverted-expander-svg" : ""}`}
			style={{
				backgroundColor: background ? thisBackground : theseColors.background,
				color: theseColors.text,
				border: theseColors.border,
			}}
		>
			{children}
		</div>
	);
}
