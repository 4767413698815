import "./NavBar.css";

export default function NavBar({ selected, setSelected, fontSize, columns }) {
	const gridColumns = `repeat(${columns.length.toString()}, 1fr)`;
	const thisFontSize = fontSize || "16px";

	return (
		<div className="nav-selector">
			<div className="nav-grid-wrapper" style={{ gridTemplateColumns: gridColumns }}>
				{columns.map((column, index) => {
					return (
						<h4
							style={{
								backgroundColor: selected === column ? "var(--Teal)" : "var(--Black)",
								color: selected === column ? "var(--Black)" : "var(--Baby)",
								fontSize: thisFontSize,
							}}
							onClick={() => setSelected(column)}
							key={index}
						>
							{column}
						</h4>
					);
				})}
			</div>
		</div>
	);
}
