import { Figura, FiguraHidden, FiguraLabel, FiguraPassword, FiguraSubmitBtn, FiguraTitle } from "react-figura";
import { confirmPasswordReset, sendEmailVerification } from "firebase/auth";
import useEmailVerification from "../Hooks/EmailVerificationHook";
import FlexGroup from "../Components/FlexGroup/FlexGroup";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout/MainLayout";
import Spacer from "../Components/Spacer/Spacer";
import useAuth from "../Hooks/AuthHook";
import React, { useState } from "react";
import { auth } from "./Firebase";

export default function Verification() {
	const [emailSent, setEmailSent] = useState(false);
	const [passwordReset, setPasswordReset] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAuth();
	useEmailVerification(user && user.emailVerified);

	const goHome = () => {
		navigate("/");
	};
	const goLogin = () => {
		navigate("/login");
	};
	const goRegistration = () => {
		navigate("/register");
	};
	const goLoginAndReload = () => {
		navigate("/login");
		window.location.reload();
	};

	function resendEmail() {
		if (user) {
			sendEmailVerification(user)
				.then(() => {
					setEmailSent(true);
				})
				.catch((error) => {
					console.error("Error sending verification email: ", error);
				});
		}
	}

	function updateNewPassword({ password }) {
		const queryParams = new URLSearchParams(location.search);
		const oobCode = queryParams.get("oobCode");
		if (oobCode) {
			confirmPasswordReset(auth, oobCode, password)
				.then(() => {
					setPasswordReset(true);
				})
				.catch((error) => {
					console.error("Error during password reset: ", error);
				});
		} else {
			console.error("Password reset code is missing");
		}
	}

	function authRoute() {
		if (user && !user.emailVerified) {
			return (
				<FlexGroup width="100%" center="center">
					{emailSent ? (
						<p className="verified">We've sent you a new verification email!</p>
					) : (
						<p className="not-verified">
							Your email has not been verified. if you need to request another verification email click
							the button below.
						</p>
					)}
					<button onClick={resendEmail} className="figura-button">
						Resend Email
					</button>
					<p className="plain-para">or</p>
					<button onClick={goHome} className="figura-button">
						Continue As Guest
					</button>
				</FlexGroup>
			);
		} else if (location.search.includes("resetPassword")) {
			return (
				<FlexGroup width="100%" center="center">
					{passwordReset ? (
						<>
							<p className="verified">Your password has been reset!</p>
							<button onClick={goLoginAndReload} className="figura-button">
								Click Here To Login
							</button>
						</>
					) : (
						<Figura formId="forgot" formStyle="figura-form" onSubmit={updateNewPassword}>
							<FiguraTitle titleStyle="figura-title">New Password</FiguraTitle>
							<FiguraPassword
								name="password"
								inputStyle="figura-input"
								errorStyle="figura-error"
								wrapper="figura-wrapper"
							>
								<FiguraLabel labelStyle="figura-label">Password:</FiguraLabel>
							</FiguraPassword>
							<FiguraHidden />
							<FiguraSubmitBtn buttonStyle="figura-submit">Submit</FiguraSubmitBtn>
						</Figura>
					)}
				</FlexGroup>
			);
		} else if (!user) {
			return (
				<FlexGroup width="100%" center="center">
					<p className="not-verified">To access this page you need to have an account and be logged in.</p>
					<button onClick={goLogin} className="figura-button">
						Login
					</button>
					<button onClick={goRegistration} className="figura-button">
						Register
					</button>
					<button onClick={goHome} className="figura-button">
						Continue As Guest
					</button>
				</FlexGroup>
			);
		} else if (user.emailVerified) {
			return (
				<FlexGroup width="100%" center="center">
					<p className="verified">Your email has been verified!</p>
					<button onClick={goLoginAndReload} className="figura-button">
						Click Here To Login
					</button>
				</FlexGroup>
			);
		} else {
			return (
				<FlexGroup width="100%" center="center">
					<p className="not-verified">Something went wrong, pleae try again!</p>
				</FlexGroup>
			);
		}
	}

	return (
		<MainLayout>
			<Spacer height="1rem" />
			{authRoute()}
		</MainLayout>
	);
}
