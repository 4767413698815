import PostMessage from "../../../Components/PostMessage/PostMessage";
import { quillFormats, quillModules } from "../../../Utils/quillConigs";
import FlexGroup from "../../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../../Layouts/MainLayout/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../../Components/NavBar/NavBar";
import Spacer from "../../../Components/Spacer/Spacer";
import Button from "../../../Components/Button/Button";
import { postFeedback } from "../../../Api/Community";
import useAuth from "../../../Hooks/AuthHook";
import { useMutation } from "react-query";
import ReactQuill from "react-quill";
import { useState } from "react";

export default function Feedback() {
	/* States */
	const [feedback, setFeedback] = useState();
	const [canContact, setCanContact] = useState("Yes");

	/* Hooks */
	const navigate = useNavigate();
	const params = useParams();
	const { uid, token } = useAuth("/login");

	/* Querys */
	const postFeedbackMutation = useMutation(postFeedback, {
		onSuccess: (data) => {
			if (data.error) return;
			setTimeout(() => {
				navigate("/");
			}, 3000);
		},
	});

	/* Functions */
	function handleDelta(content, delta, source, editor) {
		const theDelta = editor.getContents();
		setFeedback(theDelta);
	}

	function handleSubmit() {
		const feedbackType = params.feedbackType;
		postFeedbackMutation.mutate({ userId: uid, feedback, type: feedbackType, canContact, token });
	}

	return (
		<MainLayout>
			<Spacer height="1rem" />
			<h1>{params.feedbackType === "feedback" ? "Give Feedback" : "Report Problems"}</h1>
			<ReactQuill
				theme="snow"
				onChange={handleDelta}
				modules={quillModules}
				formats={quillFormats}
				placeholder="Start typing here..."
				className="ql-create"
			/>
			<FlexGroup center="center">
				<p>Can we contact you about your feedback?</p>
				<NavBar selected={canContact} setSelected={setCanContact} columns={["Yes", "No"]} />
			</FlexGroup>
			<PostMessage
				successMessage="Feedback successfully submitted! Navigating home in 3 seconds..."
				errorMessage="Error submitting feedback. Please try again."
				status={postFeedbackMutation.status}
				dataError={postFeedbackMutation.data?.error}
			>
				<FlexGroup>
					<Button text="Submit" onClickCallback={handleSubmit} />
				</FlexGroup>
			</PostMessage>
		</MainLayout>
	);
}
