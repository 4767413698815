import { HiArrowUpCircle } from "react-icons/hi2";
import { useState, useEffect } from "react";
import "./ScrollUp.css";

export default function ScrollToTopButton({ scrollContainerRef }) {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (!scrollContainerRef) return;
		const scrollContainer = scrollContainerRef.current;

		const toggleVisible = () => {
			const scrolled = scrollContainer.scrollTop;
			setVisible(scrolled > 300);
		};

		scrollContainer.addEventListener("scroll", toggleVisible);
		return () => {
			scrollContainer.removeEventListener("scroll", toggleVisible);
		};
	}, [scrollContainerRef]);

	function scrollToTop() {
		scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
	}

	return (
		<div className="scroll-up-container">
			<button onClick={scrollToTop} className="scroll-up-button" style={{ display: visible ? "inline" : "none" }}>
				<HiArrowUpCircle className="scroll-up-icon" />
			</button>
		</div>
	);
}
