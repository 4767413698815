import ExpandingCardContent from "../../../Components/ExpandingCard/ExpandingCardContent";
import { deleteWorkout, getAllWorkouts, makePrivate, makePublic } from "../../../Api/Workouts";
import ExpandingCardHeader from "../../../Components/ExpandingCard/ExpandingCardHeader";
import ExpandingCard from "../../../Components/ExpandingCard/ExpandingCard";
import PostMessage from "../../../Components/PostMessage/PostMessage";
import FlexGroup from "../../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../../Layouts/MainLayout/MainLayout";
import { groupAndCountExercises } from "../../../Utils/helpers";
import Spacer from "../../../Components/Spacer/Spacer";
import Button from "../../../Components/Button/Button";
import Query from "../../../Components/Query/Query";
import Modal from "../../../Components/Modal/Modal";
import { useMutation, useQuery } from "react-query";
import useToggler from "../../../Hooks/ToggleHook";
import useAuth from "../../../Hooks/AuthHook";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "./ProfileRoutes.css";

export default function ManageWorkouts() {
	/* States */
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showPrivacyModal, setShowPrivacyModal] = useState(false);
	const [selectedWorkout, setSelectedWorkout] = useState();
	const [thisStatus, setThisStatus] = useState();

	/* Hooks */
	const { showItem, toggleHandler } = useToggler([]);
	const { token, uid } = useAuth("/login");

	/* Querys */
	const { data, status } = useQuery("getAllWorkouts", () => getAllWorkouts(uid, token), {
		enabled: !!token,
	});
	const deleteWorkoutMutation = useMutation(deleteWorkout, {
		onSuccess: () => {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});
	const makePrivateMutation = useMutation(makePrivate, {
		onSuccess: () => {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});
	const makePublicMutation = useMutation(makePublic, {
		onSuccess: () => {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});

	/* Functions */
	function showDeleteWarning(workout) {
		setSelectedWorkout(workout);
		setShowDeleteModal(true);
	}

	function deleteWorkoutHandler(id) {
		setThisStatus("DELETE");
		deleteWorkoutMutation.mutate({ uid, id, token });
		setShowDeleteModal(false);
	}

	function showPrivacyWarning(workout) {
		setSelectedWorkout(workout);
		setShowPrivacyModal(true);
	}

	function togglePrivacy(isPublic, id) {
		setShowPrivacyModal(false);
		if (isPublic) {
			setThisStatus("PRIVATE");
			makePrivateMutation.mutate({ uid, id, token });
		} else {
			setThisStatus("PUBLIC");
			makePublicMutation.mutate({ uid, id, token });
		}
	}

	function determineMessageStatus() {
		switch (thisStatus) {
			case "DELETE":
				return deleteWorkoutMutation.status;
			case "PRIVATE":
				return makePrivateMutation.status;
			case "PUBLIC":
				return makePublicMutation.status;
			default:
				return "idle";
		}
	}

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We encountered an issue retrieving workouts. Please try again or check back later."
		>
			<MainLayout>
				<Spacer height="1rem" />
				<h2>Manage Workouts</h2>
				<FlexGroup gap="10px">
					{data &&
						data.workouts.map((workout, index) => {
							return (
								<ExpandingCard key={index} inverted={true}>
									<ExpandingCardHeader
										leftText={workout.name}
										rightText={""}
										expanded={showItem.includes(index)}
										callbackToggle={() => toggleHandler(index)}
										fontColor="var(--Baby)"
									/>
									<ExpandingCardContent expanded={showItem.includes(index)}>
										<ReactQuill
											value={workout.description}
											readOnly={true}
											modules={{ toolbar: false }}
										/>
										<Spacer height="1rem" />
										{data.exercises.map((excercise, index) => {
											const filteredExcercise = excercise.filter(
												(item) => item.workout_id === workout.id
											);
											return (
												<div className="table-wrapper" key={index}>
													{filteredExcercise[0] && (
														<div className="table-header">
															<p>Order</p>
															<p>Sets</p>
															<p>Excercise</p>
														</div>
													)}
													<div className="table-content">
														{Object.entries(groupAndCountExercises(filteredExcercise))
															.sort(([orderA], [orderB]) => orderA - orderB)
															.map(([order, { name, count, workoutId }], index) => {
																const backgroundColor =
																	index % 2 === 0 ? "var(--Teal)" : "var(--Tiffany)";
																return (
																	<div className="table-item" key={index}>
																		<div
																			className="table-grid-item"
																			style={{
																				backgroundColor: backgroundColor,
																			}}
																		>
																			<p>{order}</p>
																		</div>
																		<div
																			className="table-grid-item"
																			style={{
																				backgroundColor: backgroundColor,
																			}}
																		>
																			<p>{count}</p>
																		</div>
																		<div
																			className="table-grid-item"
																			style={{
																				backgroundColor: backgroundColor,
																			}}
																		>
																			<p>{name}</p>
																		</div>
																	</div>
																);
															})}
													</div>
												</div>
											);
										})}
										<Spacer height="1rem" />
										<div className="edit-workout-area">
											<PostMessage
												successMessage="Success! Refreshing your list in 3 seconds..."
												errorMessage="Error. Please try again."
												status={selectedWorkout === workout ? determineMessageStatus() : "idle"}
											>
												<h3 className="mw-edit" onClick={() => showPrivacyWarning(workout)}>
													Make {workout.is_public ? "Private" : "Public"}
												</h3>
												<h3 className="mw-delete" onClick={() => showDeleteWarning(workout)}>
													Delete Workout
												</h3>
											</PostMessage>
										</div>
									</ExpandingCardContent>
								</ExpandingCard>
							);
						})}
				</FlexGroup>
				<Spacer />
			</MainLayout>
			{showPrivacyModal && (
				<Modal setShowModal={setShowPrivacyModal}>
					<Spacer height="1rem" />
					<h2 style={{ color: "var(--Teal)" }}>{selectedWorkout.name}</h2>
					<Spacer height="1rem" />
					{selectedWorkout.is_public ? (
						<h3>
							Are you sure you want to make this workout private? Other users will no longer be able to
							see this workout on the communtiy page.
						</h3>
					) : (
						<h3>
							Are you sure you want to make this workout public? Other users will be able to see this
							workout on the community page.
						</h3>
					)}
					<Spacer height="1rem" />
					<FlexGroup direction="row" width="100%">
						<Button
							text="Yes"
							onClickCallback={() => togglePrivacy(selectedWorkout.is_public, selectedWorkout.id)}
						/>
					</FlexGroup>
					<Spacer height="1rem" />
				</Modal>
			)}
			{showDeleteModal && (
				<Modal setShowModal={setShowDeleteModal}>
					<Spacer height="1rem" />
					<h2 style={{ color: "var(--Teal)" }}>{selectedWorkout.name}</h2>
					<Spacer height="1rem" />
					<h3 style={{ color: "var(--Red)" }}>
						Are you sure you want to delete this workout? It will delete all of the associated history with
						this workout, and any likes inccured on the community page.
					</h3>
					<Spacer height="1rem" />
					<FlexGroup direction="row" width="100%">
						<Button text="Yes" onClickCallback={() => deleteWorkoutHandler(selectedWorkout.id)} />
					</FlexGroup>
					<Spacer height="1rem" />
				</Modal>
			)}
		</Query>
	);
}
