const apiURL = process.env.API_URL;

export function getWorkouts(selected, id, token) {
	return fetch(`${apiURL}/workouts/get_workouts?selected=${selected}&user_id=${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getExercises(token) {
	return fetch(`${apiURL}/workouts/get_exercises`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getWorkoutExercises(id, token) {
	return fetch(`${apiURL}/workouts/get_workout_exercises?workout_id=${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function postWorkout({ name, description, creator_id, is_public, exercises, token }) {
	return fetch(`${apiURL}/workouts/create_workout`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ name, description, creator_id, is_public, exercises }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function deleteWorkout({ uid, id, token }) {
	return fetch(`${apiURL}/workouts/delete_workout`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ id, uid }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function submitWorkout({ workout, token }) {
	return fetch(`${apiURL}/workouts/submit_workout`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(workout),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getWorkoutHistory(id, token) {
	return fetch(`${apiURL}/workouts/workout_history?user_id=${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function deleteWorkoutSubmission({ uid, id, token }) {
	return fetch(`${apiURL}/workouts/delete_workout_submission?workout_submission_id=${id}&user_id=${uid}`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getAllWorkouts(id, token) {
	return fetch(`${apiURL}/workouts/get_all_workouts?user_id=${id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function makePrivate({ uid, id, token }) {
	return fetch(`${apiURL}/workouts/make_workout_private?workout_id=${id}&user_id=${uid}`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function makePublic({ uid, id, token }) {
	return fetch(`${apiURL}/workouts/make_workout_public?workout_id=${id}&user_id=${uid}`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}
