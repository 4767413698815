import { HiOutlineX, HiOutlineMenu } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./Header.css";

export default function Header({ showMenu, setShowMenu }) {
	const navigate = useNavigate();
	const goHome = () => {
		navigate("/");
		setShowMenu(false);
	};

	return (
		<div className="header-container">
			<h1 onClick={goHome}>Duro</h1>
			{!showMenu ? (
				<HiOutlineMenu className="hamburger-icon" onClick={() => setShowMenu(true)} />
			) : (
				<HiOutlineX className="hamburger-icon" onClick={() => setShowMenu(false)} />
			)}
		</div>
	);
}
