import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./Firebase";

export default function useLogout() {
	const navigate = useNavigate();

	async function logout() {
		try {
			await signOut(auth).then(() => {
				navigate("/login");
			});
		} catch (error) {
			console.error("Error signing out: ", error);
		}
	}

	return logout;
}
