import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import MainLayout from "../Layouts/MainLayout/MainLayout";
import Message from "../Components/Message.js/Message";
import { isUsernameTaken } from "../Api/Community";
import Spacer from "../Components/Spacer/Spacer";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";
import * as F from "react-figura";

export default function Register() {
	const navigate = useNavigate();
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const [isLoading, setIsLoading] = useState(false);
	const goLogin = () => {
		navigate("/login");
	};

	async function handleRegister({ username, email, password }) {
		setShowFailure({ bool: false, message: "" });
		setShowSuccess(false);
		setIsLoading(true);
		try {
			const checkUsernameTaken = await isUsernameTaken(username);
			if (checkUsernameTaken.isUsernameTaken) {
				console.error("Username already taken.");
				setShowFailure({ bool: true, message: "Username already taken." });
				return;
			}
			const actionCodeSettings = {
				url: "http://localhost:3000/verify",
				handleCodeInApp: true,
			};
			const userCredential = await createUserWithEmailAndPassword(auth, email, password);
			await updateProfile(userCredential.user, { displayName: username });
			await sendEmailVerification(userCredential.user, actionCodeSettings)
				.then(() => setShowSuccess(true))
				.catch((error) => {
					setShowFailure({ bool: true, message: "Issue sending verification email" });
				});
		} catch (error) {
			console.error(error);
			if (error.code === "auth/email-already-in-use") {
				setShowFailure({ bool: true, message: "Email already in use." });
			} else if (error.code === "auth/invalid-email") {
				setShowFailure({ bool: true, message: "Invalid email." });
			} else {
				setShowFailure({
					bool: true,
					message: "There has been an issue creating your account, try again or reach out to us for help.",
				});
			}
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<>
			<MainLayout>
				<Spacer />
				<F.Figura formId="register" formStyle="figura-form" onSubmit={handleRegister}>
					<F.FiguraTitle titleStyle="figura-title">Create An Account</F.FiguraTitle>

					<F.FiguraText
						name="username"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Username:</F.FiguraLabel>
					</F.FiguraText>

					<F.FiguraEmail
						name="email"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Email:</F.FiguraLabel>
					</F.FiguraEmail>

					<F.FiguraPassword
						name="password"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Password:</F.FiguraLabel>
					</F.FiguraPassword>

					<F.FiguraConfirmPassword
						name="confirm"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Confirm Password:</F.FiguraLabel>
					</F.FiguraConfirmPassword>

					<F.FiguraHidden />

					<F.FiguraSubmitBtn buttonStyle="figura-submit">Submit</F.FiguraSubmitBtn>
				</F.Figura>
				{isLoading && <div className="spinner small-spinner"></div>}
				{showFailure.bool && <Message theme="error">{showFailure.message}</Message>}
				{showSuccess && <Message theme="success">Success! We've sent you a verification email.</Message>}

				<button onClick={goLogin} className="figura-button">
					Back To Login
				</button>
			</MainLayout>
		</>
	);
}
