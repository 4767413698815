import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { followingWorkouts, likedWorkouts, popularWorkouts, recentWorkouts } from "../Api/Community";

export default function useCommunity(uid, token, selected, currentPage) {
	const [community, setCommunity] = useState([]);
	const [reachedEnd, setReachedEnd] = useState(false);

	/* Popular Workouts
    ========================================================================*/
	const {
		data: popularData,
		status: popularStatus,
		refetch: popularRefetch,
	} = useQuery("popularWorkouts", () => popularWorkouts(currentPage, uid, token), { enabled: false });

	/* Recent Workouts
    ========================================================================*/
	const {
		data: recentData,
		status: recentStatus,
		refetch: recentRefetch,
	} = useQuery("recentWorkouts", () => recentWorkouts(currentPage, uid, token), { enabled: false });

	/* Following Workouts
    ========================================================================*/
	const {
		data: followingData,
		status: followingStatus,
		refetch: followingRefetch,
	} = useQuery("followingWorkouts", () => followingWorkouts(currentPage, uid, token), { enabled: false });

	/* Liked Workouts
    ========================================================================*/
	const {
		data: likedData,
		status: likedStatus,
		refetch: likedRefetch,
	} = useQuery("likedWorkouts", () => likedWorkouts(currentPage, uid, token), { enabled: false });

	/* Hook to update the community array when the selected tab changes
    ========================================================================*/
	// This useEffect programmatically refetches the data when the selected tab changes and on mount
	useEffect(() => {
		if (token) {
			if (selected === "Popular" && !reachedEnd) {
				popularRefetch();
			} else if (selected === "Recent" && !reachedEnd) {
				recentRefetch();
			} else if (selected === "Following" && !reachedEnd) {
				followingRefetch();
			} else if (selected === "Liked" && !reachedEnd) {
				likedRefetch();
			}
		}
		// eslint-disable-next-line
	}, [selected, token, currentPage, popularRefetch, recentRefetch, followingRefetch, likedRefetch]);

	// This useEffect updates the community array after the data is fetched
	useEffect(() => {
		const updateCommunityData = (newData) => {
			if (newData && newData.length < 50) {
				setReachedEnd(true);
			}
			if (!reachedEnd && newData && community && community.length >= 50 && currentPage > 1) {
				setCommunity((prev) => [...prev, ...newData]);
			} else if (newData) {
				setCommunity(newData);
			}
		};

		if (selected === "Popular") {
			updateCommunityData(popularData);
		} else if (selected === "Recent") {
			updateCommunityData(recentData);
		} else if (selected === "Following") {
			updateCommunityData(followingData);
		} else if (selected === "Liked") {
			updateCommunityData(likedData);
		}
		// eslint-disable-next-line
	}, [selected, popularData, recentData, followingData, likedData]);

	function determineStatus() {
		if (selected === "Popular") {
			return popularStatus;
		} else if (selected === "Recent") {
			return recentStatus;
		} else if (selected === "Following") {
			return followingStatus;
		} else if (selected === "Liked") {
			return likedStatus;
		} else if (selected === "Search") {
			return "success";
		}
	}

	return {
		community,
		likedStatus,
		recentStatus,
		popularStatus,
		followingStatus,
		setCommunity,
		setReachedEnd,
		determineStatus,
	};
}
