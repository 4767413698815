import PostMessage from "../../Components/PostMessage/PostMessage";
import useCreateValidation from "../../Hooks/CreateValidationHook";
import { quillFormats, quillModules } from "../../Utils/quillConigs";
import CreateModal from "../../Components/Create/CreateModal";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import CreateTable from "../../Components/Create/CreateTable";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import TextInput from "../../Components/TextInput/TextInput";
import Message from "../../Components/Message.js/Message";
import NavBar from "../../Components/NavBar/NavBar";
import Divider from "../../Components/Divider/Divider";
import Search from "../../Components/Search/Search";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import { useMutation, useQuery } from "react-query";
import Select from "../../Components/Select/Select";
import Query from "../../Components/Query/Query";
import { getExercises } from "../../Api/Workouts";
import { postWorkout } from "../../Api/Workouts";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/AuthHook";
import ReactQuill from "react-quill";
import { useState } from "react";
import "./Create.css";

export default function Create() {
	/* States */
	const [tempWorkout, setTempWorkout] = useState({ workoutName: "", workoutDescription: {}, exercises: [] });
	const [showModal, setShowModal] = useState(false);
	const [selectedExercise, setSelectedExercise] = useState(null);
	const [selectedPrivacy, setSelectedPrivacy] = useState("Public");
	const [descError, setDescError] = useState(false);

	/* Hooks */
	const navigate = useNavigate();
	const { token, uid } = useAuth("/login");
	const validation = useCreateValidation();

	/* Querys */
	const { data, status } = useQuery("getExercises", () => getExercises(token), {
		enabled: !!token,
	});
	const createWorkoutMutation = useMutation(postWorkout, {
		onSuccess: (data) => {
			if (data.error) return;
			setTimeout(() => {
				navigate("/");
			}, 3000);
		},
	});
	function handleCreateWorkout() {
		const validWorkout = validation.handleValidatingWorkout(tempWorkout);
		if (!validWorkout) return;
		const workoutDetails = {
			name: tempWorkout.workoutName,
			description: tempWorkout.workoutDescription,
			creator_id: uid,
			is_public: selectedPrivacy === "Public" ? true : false,
			exercises: tempWorkout.exercises,
			token: token,
		};
		createWorkoutMutation.mutate(workoutDetails);
	}

	/* Functions */
	function setWorkoutName(name) {
		setTempWorkout({ ...tempWorkout, workoutName: name });
	}

	function handleDescription(content, delta, source, editor) {
		const theDelta = editor.getContents();
		if (validation.descriptionOverLimit(theDelta)) {
			setDescError(true);
		} else {
			setDescError(false);
		}
		setTempWorkout({ ...tempWorkout, workoutDescription: theDelta });
	}

	function confirmExercise() {
		if (!selectedExercise) return;
		setShowModal(true);
	}

	function searchFunction(query) {
		return data.filter((item) => {
			return item.name.toLowerCase().includes(query);
		});
	}

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We encountered an issue retrieving exercises. Please try again or check back later."
		>
			<>
				<MainLayout>
					<Spacer height="1rem" />
					<h2>Create A Workout Name</h2>
					<TextInput placeholder="Workout Name..." onChangeCallback={setWorkoutName} fontSize={"20px"} />
					<Divider />
					<h2>Give It A Description</h2>
					<ReactQuill
						theme="snow"
						onChange={handleDescription}
						modules={quillModules}
						formats={quillFormats}
						value={tempWorkout.workoutDescription}
						placeholder="Workout Description..."
						className="ql-create"
					/>
					{descError && (
						<Message theme="error">Workout description must be less than 1000 characters.</Message>
					)}
					<Divider />
					<h2>Add Exercises</h2>
					<Search
						searchFunction={searchFunction}
						selected={selectedExercise}
						setSelected={setSelectedExercise}
						expand={true}
					/>
					<Select options={data} selected={selectedExercise} setSelected={setSelectedExercise} />
					<FlexGroup>
						<Button text="Add Exercise" onClickCallback={confirmExercise} />
						<CreateTable tempWorkout={tempWorkout} setTempWorkout={setTempWorkout} />
					</FlexGroup>
					<Divider />
					<h2>Privacy</h2>
					<FlexGroup>
						<p>
							If you set this workout to public it will be visible to others on the community page. You
							can always toggle this setting from the Manage Workouts section of your profile.
						</p>
						<NavBar
							selected={selectedPrivacy}
							setSelected={setSelectedPrivacy}
							columns={["Public", "Private"]}
						/>
					</FlexGroup>
					<Divider />
					<h2>Confirm Your Workout</h2>
					{validation.error.length > 0 && (
						<Message theme="error">
							{validation.error.map((error, index) => {
								return error + " ";
							})}
						</Message>
					)}
					<PostMessage
						successMessage="Workout successfully created! Navigating home in 3 seconds..."
						errorMessage="Error creating workout. Please try again."
						status={createWorkoutMutation.status}
						dataError={createWorkoutMutation.data?.error}
					>
						<FlexGroup>
							<Button text="Complete" onClickCallback={handleCreateWorkout} />
						</FlexGroup>
					</PostMessage>
					<Spacer height="1rem" />
				</MainLayout>
				<CreateModal
					showModal={showModal}
					setShowModal={setShowModal}
					selectedExercise={selectedExercise}
					setSelectedExercise={setSelectedExercise}
					tempWorkout={tempWorkout}
					setTempWorkout={setTempWorkout}
				/>
			</>
		</Query>
	);
}
