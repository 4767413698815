import { useEffect, useRef, useState } from "react";

export default function ExpandingCardContent({ children, expanded }) {
	const containerRef = useRef(null);
	const contentRef = useRef(null);
	const [height, setHeight] = useState(expanded ? "auto" : "0px");

	useEffect(() => {
		if (containerRef.current && contentRef.current) {
			if (expanded) {
				setHeight(`${contentRef.current.scrollHeight}px`);
				setTimeout(() => setHeight("auto"), 300);
			} else {
				setHeight(`${contentRef.current.scrollHeight}px`);
				setTimeout(() => setHeight("0px"), 10);
			}
		}
	}, [expanded]);

	return (
		<div className={`expanding-card-content ${expanded ? "expanded" : ""}`} style={{ height }} ref={containerRef}>
			<div ref={contentRef}>{children}</div>
		</div>
	);
}
