import { groupAndCountExercises } from "../../Utils/helpers";

export default function CommunityModalTable({ modalData }) {
	const loading = modalData && modalData.exercises ? false : true;

	return (
		<>
			{loading && (
				<div className="community-modal-loading">
					<div className="spinner" />
				</div>
			)}
			{modalData && modalData.exercises && modalData.exercises.length > 0 && (
				<div className="table-wrapper">
					<div className="table-header">
						<p>Order</p>
						<p>Sets</p>
						<p>Excercise</p>
					</div>
					<div className="table-content">
						{Object.entries(groupAndCountExercises(modalData.exercises))
							.sort(([orderA], [orderB]) => orderA - orderB)
							.map(([order, { name, count }], index) => {
								const backgroundColor = index % 2 === 0 ? "var(--Teal)" : "var(--Tiffany)";
								return (
									<div className="table-item" key={index}>
										<div className="table-grid-item" style={{ backgroundColor: backgroundColor }}>
											<p>{order}</p>
										</div>
										<div className="table-grid-item" style={{ backgroundColor: backgroundColor }}>
											<p>{count}</p>
										</div>
										<div className="table-grid-item" style={{ backgroundColor: backgroundColor }}>
											<p>{name}</p>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			)}
		</>
	);
}
