export const assets = [{ type: "font", src: "Apercu" }];

export function formateDate(isoDateString) {
	const date = new Date(isoDateString);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`; //2023-11-05
}

let timeoutId;
export function debounce(ms, action) {
	return (...args) => {
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			action(...args);
		}, ms);
	};
}

export function groupAndCountExercises(exercises) {
	const groupedExercises = {};
	exercises.forEach((exercise) => {
		if (!groupedExercises[exercise.order]) {
			groupedExercises[exercise.order] = {
				name: exercise.name,
				count: 0,
			};
		}
		groupedExercises[exercise.order].count++;
	});
	return groupedExercises;
}

export default function consoleLog() {
	console.log(
		"%cIf you're seeing this I assume your a fellow developer! Reach out and lets talk about code.",
		"color: #59d7ee; font-family: sans-serif; font-size: 1rem; font-weight: bold;"
	);
}
