const apiURL = process.env.API_URL;

export function followUser({ follower_id, followed_id, token }) {
	return fetch(`${apiURL}/community/follow`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ follower_id, followed_id }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function unfollowUser({ follower_id, followed_id, token }) {
	return fetch(`${apiURL}/community/unfollow`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ follower_id, followed_id }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function likeWorkout({ user_id, workout_id, token }) {
	return fetch(`${apiURL}/community/like`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ user_id, workout_id }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function unlikeWorkout({ user_id, workout_id, token }) {
	return fetch(`${apiURL}/community/unlike`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ user_id, workout_id }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function userWorkouts(user_id, creator_id, token) {
	return fetch(`${apiURL}/community/user-workouts?user_id=${user_id}&creator_id=${creator_id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function popularWorkouts(page, user_id, token) {
	return fetch(`${apiURL}/community/top-workouts?page=${page}&user_id=${user_id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function recentWorkouts(page, user_id, token) {
	return fetch(`${apiURL}/community/recent-workouts?page=${page}&user_id=${user_id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function followingWorkouts(page, user_id, token) {
	return fetch(`${apiURL}/community/following-workouts?page=${page}&user_id=${user_id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function likedWorkouts(page, user_id, token) {
	return fetch(`${apiURL}/community/liked-workouts?page=${page}&user_id=${user_id}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function searchUsers(query, user_id, token) {
	return fetch(`${apiURL}/community/search?user_id=${user_id}&query=${query}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function isUsernameTaken(username) {
	return fetch(`${apiURL}/community/check-username?username=${username}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function postNewUsername({ id, username, token }) {
	return fetch(`${apiURL}/community/update-username`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ id, username }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function deleteAccount({ uid, token }) {
	return fetch(`${apiURL}/community/delete-account`, {
		method: "DELETE",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ uid }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function postFeedback({ userId, feedback, type, canContact, token }) {
	return fetch(`${apiURL}/community/feedback`, {
		method: "POST",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify({ userId, feedback, canContact, type }),
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}

export function getFeedback(userId, token) {
	return fetch(`${apiURL}/community/get-feedback?user_id=${userId}`, {
		method: "GET",
		mode: "cors",
		headers: {
			"Content-Type": "application/json; charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => response.json())
		.catch((error) => console.error("Error:", error));
}
