import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import React from "react";
import App from "./App";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
const googleID = process.env.GOOGLE_ID;
ReactGA.initialize(googleID);

root.render(
	<QueryClientProvider client={queryClient}>
		<App />
	</QueryClientProvider>
);
const SendAnalytics = () => {
	ReactGA.send({
		hitType: "pageview",
		page: window.location.pathname,
	});
};

reportWebVitals(SendAnalytics);
