import { onAuthStateChanged, getIdToken } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { auth } from "../Auth/Firebase";

export default function useAuth(route) {
	const [isVerified, setIsVerified] = useState(true);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [uid, setUid] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
			setUser(currentUser);
			setLoading(false);
			if (currentUser) {
				if (currentUser.emailVerified) {
					setIsVerified(currentUser.emailVerified);
					const currentUserToken = await getIdToken(currentUser);
					setToken(currentUserToken);
				}
				setUid(currentUser.uid);
			} else {
				route && navigate(route);
			}
		});
		return () => unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, user, isVerified, token, uid };
}
