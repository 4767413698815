import "./Message.css";

export default function Message({ theme, children }) {
	let themeColor = "var(--Baby)";
	if (theme === "error") {
		themeColor = "var(--Red)";
	} else if (theme === "success") {
		themeColor = "var(--Green)";
	}

	return (
		<div className="message-wrapper" style={{ borderColor: themeColor }}>
			<p style={{ color: themeColor }}>{children}</p>
		</div>
	);
}
