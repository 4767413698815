import { Figura, FiguraSubmitBtn, FiguraLabel, FiguraEmail } from "react-figura";
import PostMessage from "../PostMessage/PostMessage";
import FlexGroup from "../FlexGroup/FlexGroup";
import { updateEmail } from "firebase/auth";
import Spacer from "../Spacer/Spacer";
import Modal from "../Modal/Modal";
import { useState } from "react";

export default function UpdateEmail({ setShowUpdateEmail, user }) {
	const [status, setStatus] = useState("idle");
	const [errorMessage, setErrorMessage] = useState("Error updating email. Please try again or check back later.");

	async function handleUpdatingEmail({ email }) {
		setStatus("loading");
		if (user) {
			updateEmail(user, email)
				.then(() => {
					setStatus("success");
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				})
				.catch((error) => {
					if (error.code === "auth/requires-recent-login") {
						setErrorMessage(
							"You have not logged in for awhile, please log out and back in to change your email."
						);
					}
					setStatus("error");
					console.error("Error updating email:", error);
				});
		} else {
			setStatus("error");
		}
	}

	return (
		<Modal setShowModal={setShowUpdateEmail}>
			<h2 className="modal-title">Update Email</h2>
			<Spacer height="1rem" />
			<h3>Current Email: {user.email}</h3>
			<Figura formId="update-email" formStyle="profile-figura-form" onSubmit={handleUpdatingEmail}>
				<FiguraEmail name="email" inputStyle="figura-input" errorStyle="figura-error" wrapper="figura-wrapper">
					<FiguraLabel labelStyle="figura-label">New Email</FiguraLabel>
				</FiguraEmail>
				<FiguraSubmitBtn buttonStyle="figura-submit">Submit</FiguraSubmitBtn>
			</Figura>
			<Spacer height="1rem" />
			<FlexGroup width="100%" center="center">
				<PostMessage
					successMessage="Email updated! Refreshing in 3 seconds..."
					errorMessage={errorMessage}
					status={status}
				></PostMessage>
			</FlexGroup>
			<Spacer height="1rem" />
		</Modal>
	);
}
