export default function FlexGroup({ children, gap, direction, width, center }) {
	const thisGap = gap ? gap : "2rem";
	const thisDirection = direction ? direction : "column";
	const thisWidth = width ? width : "90%";
	const thisCenter = center === "center" ? "center" : null;

	return (
		<div
			className="create-button-group"
			style={{
				width: thisWidth,
				display: "flex",
				gap: thisGap,
				flexDirection: thisDirection,
				justifyContent: thisCenter,
				alignItems: thisCenter,
			}}
		>
			{children}
		</div>
	);
}
