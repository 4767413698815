import { signInWithEmailAndPassword } from "firebase/auth";
import MainLayout from "../Layouts/MainLayout/MainLayout";
import Message from "../Components/Message.js/Message";
import Spacer from "../Components/Spacer/Spacer";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";
import * as F from "react-figura";
import "./Auth.css";

export default function Login() {
	const navigate = useNavigate();
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const goRegistration = () => {
		navigate("/register");
	};
	const goForgot = () => {
		navigate("/forgot-password");
	};
	const goHome = () => {
		navigate("/");
	};

	async function handleLogin({ email, password }) {
		setShowFailure({ bool: false, message: "" });
		try {
			await signInWithEmailAndPassword(auth, email, password).then(() => {
				navigate("/");
			});
		} catch (error) {
			setShowFailure({ bool: true, message: "Invalid login credentials." });
		}
	}

	return (
		<>
			<MainLayout>
				<Spacer />
				<F.Figura formId="login" formStyle="figura-form" onSubmit={handleLogin}>
					<F.FiguraTitle titleStyle="figura-title">Login</F.FiguraTitle>

					<F.FiguraEmail
						name="email"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Email:</F.FiguraLabel>
					</F.FiguraEmail>

					<F.FiguraPassword
						name="password"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<F.FiguraLabel labelStyle="figura-label">Password:</F.FiguraLabel>
					</F.FiguraPassword>

					<F.FiguraHidden />

					<F.FiguraSubmitBtn buttonStyle="figura-submit">Submit</F.FiguraSubmitBtn>
				</F.Figura>

				{showFailure.bool && <Message theme="error">{showFailure.message}</Message>}

				<p className="lower-message">If you dont have an account please click below to create one.</p>

				<button onClick={goRegistration} className="figura-button">
					Create Account
				</button>

				<button onClick={goHome} className="figura-button">
					Continue As Guest
				</button>

				<p className="forgot" onClick={goForgot}>
					Forgot Password?
				</p>
			</MainLayout>
		</>
	);
}
