import ExpandingCardContent from "../../Components/ExpandingCard/ExpandingCardContent";
import ExpandingCardHeader from "../../Components/ExpandingCard/ExpandingCardHeader";
import ExpandingCard from "../../Components/ExpandingCard/ExpandingCard";
import PostMessage from "../../Components/PostMessage/PostMessage";
import useWorkoutSubmission from "../../Hooks/SubmissionHook";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import NumInput from "../../Components/NumInput/NumInput";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Message from "../../Components/Message.js/Message";
import { getWorkoutExercises } from "../../Api/Workouts";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import Query from "../../Components/Query/Query";
import React, { useEffect, useState } from "react";
import useToggler from "../../Hooks/ToggleHook";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/AuthHook";
import { useQuery } from "react-query";
import ReactQuill from "react-quill";
import "./Submission.css";

export default function Workout() {
	/* States */
	const [selectedWorkout, setSelectedWorkout] = useState();

	/* Hooks */
	const navigate = useNavigate();
	const { showItem, toggleHandler } = useToggler(["1-1"]);
	const { token, uid } = useAuth();
	const WO = useWorkoutSubmission(selectedWorkout, uid, token);

	/* Querys */
	const { data, status } = useQuery(
		["getWorkouts", selectedWorkout],
		() => getWorkoutExercises(selectedWorkout, token),
		{ enabled: !!token && !!selectedWorkout }
	);

	/* Functions */
	function goHome() {
		navigate("/");
	}

	useEffect(() => {
		const thisWorkout = localStorage.getItem("selectedWorkout");
		if (!thisWorkout) {
			navigate("/start");
		} else {
			setSelectedWorkout(parseInt(thisWorkout));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We encountered an issue retrieving workouts. Please try again or check back later."
		>
			{data && data.exercises && data.workout && (
				<MainLayout>
					<Spacer height="1rem" />
					<h2>{data.workout.name}</h2>
					<ReactQuill value={data.workout.description} readOnly={true} modules={{ toolbar: false }} />
					<FlexGroup gap="10px">
						{data.exercises
							.sort((a, b) => a.order - b.order || a.set - b.set)
							.map((exercise) => {
								const exerciseKey = `${exercise.set}-${exercise.order}`;
								return (
									<React.Fragment key={exerciseKey}>
										{exercise.set === 1 && <p className="exercise-header">#{exercise.order}</p>}
										<ExpandingCard>
											<ExpandingCardHeader
												leftText={`Set ${exercise.set}`}
												rightText={exercise.name}
												expanded={showItem.includes(exerciseKey)}
												callbackToggle={() => toggleHandler(exerciseKey)}
											/>
											<ExpandingCardContent
												expanded={showItem.includes(exerciseKey)}
												key={exerciseKey}
											>
												<div className="submission-input-group">
													{exercise.reps && (
														<NumInput
															title="Reps:"
															onChangeCallback={WO.updateExercises}
															callbackParams={{
																metric: "reps",
																workoutExerciseId: exercise.workout_exercise_id,
															}}
														/>
													)}
													{exercise.distance && (
														<NumInput
															title="Distance:"
															metricUnit={exercise.distance_unit}
															onChangeCallback={WO.updateExercises}
															callbackParams={{
																metric: "distance",
																workoutExerciseId: exercise.workout_exercise_id,
															}}
														/>
													)}
													{exercise.time && (
														<NumInput
															title="Time:"
															metricUnit={"seconds"}
															onChangeCallback={WO.updateExercises}
															callbackParams={{
																metric: "time",
																workoutExerciseId: exercise.workout_exercise_id,
															}}
														/>
													)}
													{exercise.weight && (
														<NumInput
															title="Weight:"
															metricUnit={exercise.weight_unit}
															onChangeCallback={WO.updateExercises}
															callbackParams={{
																metric: "weight",
																workoutExerciseId: exercise.workout_exercise_id,
															}}
														/>
													)}
												</div>
											</ExpandingCardContent>
										</ExpandingCard>
									</React.Fragment>
								);
							})}
					</FlexGroup>
					{WO.error && (
						<Message theme="error">
							You have not completed all exercises. Please complete all exercises before submitting.
						</Message>
					)}
					<PostMessage
						successMessage="Workout successfully submitted! Navigating home in 3 seconds..."
						errorMessage="Error submitting workout. Please try again."
						status={WO.submitWorkoutMutation.status}
					>
						<FlexGroup gap="1rem">
							<Button
								text="Finish Workout"
								onClickCallback={() => WO.executeWorkoutSubmission(data.exercises.length)}
							/>
							<Button text="Cancel Workout" onClickCallback={goHome} />
						</FlexGroup>
					</PostMessage>
					<Spacer height="1rem" />
				</MainLayout>
			)}
		</Query>
	);
}
