import "./TextInput.css";

export default function TextInput({ placeholder, width, fontSize, onChangeCallback }) {
	const thisWidth = width ? width : "90%";
	const thisFontSize = fontSize ? fontSize : "24px";

	function callbackSetText(e) {
		e.preventDefault();
		onChangeCallback(e.target.value);
	}

	return (
		<input
			type="text"
			placeholder={`${placeholder ? placeholder : ""}`}
			style={{ width: thisWidth, fontSize: thisFontSize }}
			onChange={callbackSetText}
			className="text-input-component"
		/>
	);
}
