import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/AuthHook";
import useLogout from "../../Auth/Logout";
import "./Menu.css";

export default function Menu({ showMenu, setShowMenu }) {
	const navigate = useNavigate();
	const location = useLocation();
	const { isVerified } = useAuth();
	const logout = useLogout();

	function goLink(link) {
		if (location.pathname !== link) {
			navigate(link);
		} else {
			setShowMenu(false);
		}
	}

	return (
		<div className={`${showMenu ? "show-menu" : ""} menu-container`}>
			{!isVerified && <h2 onClick={() => goLink("/login")}>Login</h2>}
			<h2 onClick={() => goLink("/profile")}>Profile</h2>
			<h2 onClick={() => goLink("/start")}>Start Workout</h2>
			<h2 onClick={() => goLink("/create")}>Create Workout</h2>
			<h2 onClick={() => goLink("/history")}>Workout History</h2>
			<h2 onClick={() => goLink("/community")}>Community</h2>
			<h2 onClick={() => goLink("/legal")}>Legal</h2>
			<h2 onClick={() => goLink("/support")}>Support</h2>
			{isVerified && <h2 onClick={() => logout()}>Logout</h2>}
		</div>
	);
}
