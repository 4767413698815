import UpdateUsername from "../../Components/Profile/UpdateUsername";
import UpdatePassword from "../../Components/Profile/UpdatePassword";
import DeleteAccount from "../../Components/Profile/DeleteAccount";
import UpdateEmail from "../../Components/Profile/UpdateEmail";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/AuthHook";
import useLogout from "../../Auth/Logout";
import { useState } from "react";
import "./Profile.css";

export default function Profile() {
	const [showUpdateUsername, setShowUpdateUsername] = useState(false);
	const [showUpdatePassword, setShowUpdatePassword] = useState(false);
	const [showUpdateEmail, setShowUpdateEmail] = useState(false);
	const [showDeleteAccount, setShowDeleteAccount] = useState(false);
	const { user, uid, token } = useAuth("/login");
	const navigate = useNavigate();
	const logout = useLogout();

	return (
		<>
			<MainLayout>
				<Spacer />
				<h2>Profile for @{user && user.displayName}</h2>
				<FlexGroup>
					<Button text="Manage Workouts" onClickCallback={() => navigate("/profile/manage-workouts")} />
					<Button text="Manage Subscription" onClickCallback={() => navigate("/profile/manage-sub")} />
					<Button text="Update Username" onClickCallback={() => setShowUpdateUsername(true)} />
					<Button text="Update Password" onClickCallback={() => setShowUpdatePassword(true)} />
					<Button text="Update Email" onClickCallback={() => setShowUpdateEmail(true)} />
					<Button text="Logout" onClickCallback={() => logout()} />
					<Button text="Delete Account" onClickCallback={() => setShowDeleteAccount(true)} />
					<Spacer />
				</FlexGroup>
			</MainLayout>
			{showUpdateUsername && (
				<UpdateUsername setShowUpdateUsername={setShowUpdateUsername} uid={uid} token={token} />
			)}
			{showUpdatePassword && <UpdatePassword setShowUpdatePassword={setShowUpdatePassword} user={user} />}
			{showUpdateEmail && <UpdateEmail setShowUpdateEmail={setShowUpdateEmail} user={user} />}
			{showDeleteAccount && (
				<DeleteAccount setShowDeleteAccount={setShowDeleteAccount} user={user} uid={uid} token={token} />
			)}
		</>
	);
}
