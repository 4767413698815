import { submitWorkout } from "../Api/Workouts";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

export default function useWorkoutSubmission(selectedWorkout, uid, token) {
	const navigate = useNavigate();
	const [workout, setWorkout] = useState({
		workoutSubmission: {},
		exerciseSubmissions: [],
	});
	const [error, setError] = useState(false);

	useEffect(() => {
		if (uid && selectedWorkout) {
			setWorkout((prevWorkout) => ({
				...prevWorkout,
				workoutSubmission: {
					...prevWorkout.workoutSubmission,
					userId: uid,
					workoutId: selectedWorkout,
				},
			}));
		}
	}, [uid, selectedWorkout]);

	function updateExercises(value, { workoutExerciseId, metric }) {
		setError(false);
		setWorkout((prevWorkout) => {
			const existingIndex = prevWorkout.exerciseSubmissions.findIndex(
				(submission) => submission.workoutExerciseId === workoutExerciseId
			);

			let newSubmissions = [...prevWorkout.exerciseSubmissions];

			if (existingIndex !== -1) {
				newSubmissions[existingIndex] = {
					...newSubmissions[existingIndex],
					[metric]: Number(value),
					workoutExerciseId,
				};
			} else {
				newSubmissions.push({
					[metric]: Number(value),
					workoutExerciseId,
				});
			}

			return {
				...prevWorkout,
				exerciseSubmissions: newSubmissions,
			};
		});
	}

	const submitWorkoutMutation = useMutation(submitWorkout, {
		onSuccess: () => {
			setTimeout(() => {
				navigate("/");
			}, 3000);
		},
	});

	function executeWorkoutSubmission(exercisesTotal) {
		if (exercisesTotal !== workout.exerciseSubmissions.length) {
			setError(true);
			return;
		}
		submitWorkoutMutation.mutate({ workout, token });
	}

	return {
		error,
		submitWorkoutMutation,
		updateExercises,
		executeWorkoutSubmission,
	};
}
