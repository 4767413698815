export default function CommunityMessage({ community, selected }) {
	return (
		<div className="community-feed-card">
			{selected === "Popular" && <p>These are the most popular workouts based off likes within the community.</p>}
			{selected === "Recent" && <p>These are the most recent workouts created within the community.</p>}
			{selected === "Following" && (
				<>
					{community && community.length > 0 ? (
						<p>These are all the workouts from people that you follow.</p>
					) : (
						<p>Once you follow other users, there workouts will display here.</p>
					)}
				</>
			)}
			{selected === "Liked" && (
				<>
					{community && community.length > 0 ? (
						<p>These are all the workouts that you have liked.</p>
					) : (
						<p>Once you have liked workouts, they will show up here.</p>
					)}
				</>
			)}
			{selected === "Search" && (
				<p>
					Search by username, workout name, or workout description. Results are organized by best match, and
					most liked.
				</p>
			)}
		</div>
	);
}
