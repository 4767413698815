import { useEffect, useRef, useState } from "react";

export default function useCommunityDescriptionHeight(showItem, index, post) {
	const maxDescriptionHeight = 140;
	const descHeight = showItem.includes(index) ? "100%" : `${maxDescriptionHeight}px`;

	const [contentIsOverflowing, setContentIsOverflowing] = useState(false);
	const quillWrapperRef = useRef(null);

	useEffect(() => {
		if (quillWrapperRef.current) {
			const height = quillWrapperRef.current.clientHeight;
			setContentIsOverflowing(height > maxDescriptionHeight - 1);
		}
	}, [quillWrapperRef, post.description]);

	function determineExpandMessage() {
		if (!showItem.includes(index) && contentIsOverflowing) {
			return "See more >>";
		} else if (showItem.includes(index)) {
			return "See less <<";
		} else {
			return " ";
		}
	}

	return { descHeight, quillWrapperRef, determineExpandMessage };
}
