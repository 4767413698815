import ManageSubscription from "./Pages/Profile/ProfileRoutes/ManageSubscription.js";
import ManageWorkouts from "./Pages/Profile/ProfileRoutes/ManageWorkouts.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Personal from "./Pages/Community/CommunityRoutes/Personal.js";
import Feedback from "./Pages/Support/SupportRoutes/Feedback.js";
import Community from "./Pages/Community/Community.js";
import Submission from "./Pages/Submission/Submission.js";
import consoleLog, { assets } from "./Utils/helpers.js";
import Loader from "./Components/Loader/Loader.js";
import Support from "./Pages/Support/Support.js";
import History from "./Pages/History/History.js";
import Verification from "./Auth/Verification.js";
import Create from "./Pages/Create/Create.js";
import Admin from "./Pages/Admin/Admin.js";
import ForgotPwd from "./Auth/ForgotPwd.js";
import Profile from "./Pages/Profile/Profile.js";
import Home from "./Pages/Home/Home.js";
import Legal from "./Pages/Legal/Legal.js";
import Start from "./Pages/Start/Start.js";
import Register from "./Auth/Register.js";
import "react-quill/dist/quill.snow.css";
import Login from "./Auth/Login.js";
import Onus from "react-onus";
import "./Utils/Globals.css";
import "./App.css";

export default function App() {
	consoleLog();
	return (
		<div className="app">
			<Router>
				<Onus assets={assets} loader={<Loader />}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/start" element={<Start />} />
						<Route path="/submission" element={<Submission />} />
						<Route path="/create" element={<Create />} />
						<Route path="/history" element={<History />} />
						<Route path="/community" element={<Community />} />
						<Route path="/community/:id" element={<Personal />} />
						<Route path="/legal" element={<Legal />} />
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/profile/manage-sub" element={<ManageSubscription />} />
						<Route path="/profile/manage-workouts" element={<ManageWorkouts />} />
						<Route path="/support" element={<Support />} />
						<Route path="/support/:feedbackType" element={<Feedback />} />
						<Route path="/admin" element={<Admin />} />
						<Route path="/verification" element={<Verification />} />
						<Route path="/forgot-password" element={<ForgotPwd />} />
					</Routes>
				</Onus>
			</Router>
		</div>
	);
}
