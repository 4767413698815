import FlexGroup from "../../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../../Layouts/MainLayout/MainLayout";
import Spacer from "../../../Components/Spacer/Spacer";

export default function ManageSubscription() {
	return (
		<MainLayout>
			<Spacer height="1rem" />
			<FlexGroup center="center">
				<h2>
					Subscriptions are coming in a future version of the application, and will allow you to create the
					amount of workouts you can submit based off your subscription!
				</h2>
			</FlexGroup>
		</MainLayout>
	);
}
