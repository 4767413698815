import Message from "../Message.js/Message";

export default function PostMessage({ errorMessage, dataError, successMessage, status, children }) {
	if (dataError) {
		return <Message theme="error">{dataError}</Message>;
	} else if (status === "loading") {
		return <Message>Loading...</Message>;
	} else if (status === "error") {
		return <Message theme="error">{errorMessage}</Message>;
	} else if (status === "success") {
		return <Message theme="success">{successMessage}</Message>;
	} else if (status === "idle") {
		return children;
	}
}
