import { useCallback, useRef, useState } from "react";

export default function usePagination() {
	const [currentPage, setCurrentPage] = useState(1);

	const observer = useRef();
	const nextPageElement = useCallback((node) => {
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				setCurrentPage((prevPage) => prevPage + 1);
			}
		});
		if (node) observer.current.observe(node);
	}, []);

	return {
		currentPage,
		setCurrentPage,
		nextPageElement,
	};
}
