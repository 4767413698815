import ExpandingCardContent from "../../Components/ExpandingCard/ExpandingCardContent";
import ExpandingCardHeader from "../../Components/ExpandingCard/ExpandingCardHeader";
import { deleteWorkoutSubmission, getWorkoutHistory } from "../../Api/Workouts";
import ExpandingCard from "../../Components/ExpandingCard/ExpandingCard";
import PostMessage from "../../Components/PostMessage/PostMessage";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Search from "../../Components/Search/Search";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import { useMutation, useQuery } from "react-query";
import Query from "../../Components/Query/Query";
import Modal from "../../Components/Modal/Modal";
import useToggler from "../../Hooks/ToggleHook";
import { formateDate } from "../../Utils/helpers";
import useAuth from "../../Hooks/AuthHook";
import { useState } from "react";
import "../../Utils/Globals.css";
import "./History.css";

export default function History() {
	/* States */
	const [searchResults, setSearchResults] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [workoutToDelete, setWorkoutToDelete] = useState(null);

	/* Hooks */
	const { showItem, toggleHandler } = useToggler(["0-1"]);
	const { token, uid } = useAuth("/login");

	/* Querys */
	const { data, status } = useQuery(["getWorkouts", uid], () => getWorkoutHistory(uid, token), {
		enabled: !!token && !!uid,
		onSuccess: (data) => {
			return (
				data &&
				data.length > 0 &&
				data.sort((a, b) => {
					const dateA = new Date(a.workout_date);
					const dateB = new Date(b.workout_date);
					return dateB - dateA;
				})
			);
		},
	});
	const deleteWorkoutMutation = useMutation(deleteWorkoutSubmission, {
		onSuccess: () => {
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		},
	});

	/* Functions */
	function searchFunction(query) {
		const results = data.filter((item) => {
			const dateMatch = formateDate(item.workout_date).toLowerCase().includes(query);
			const nameMatch = item.workout_name.toLowerCase().includes(query);
			return dateMatch || nameMatch;
		});
		setSearchResults(results);
	}

	function handleDeleteWorkout(id) {
		deleteWorkoutMutation.mutate({ uid, id, token });
	}

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We had an issue retrieving your workout history. Please try again or check back later."
		>
			<MainLayout>
				<Spacer height="1rem" />
				<h2>Your Workout History</h2>
				<Search searchFunction={searchFunction} placeholder="Search by date or workout..." expand={false} />
				<FlexGroup gap="10px">
					{data &&
						Array.isArray(data) &&
						data.length > 0 &&
						(searchResults || data).map((workout, index) => {
							const toggleKey = `${index}-${workout.submission_id}`;
							return (
								<ExpandingCard key={toggleKey} inverted={true}>
									<ExpandingCardHeader
										leftText={formateDate(workout.workout_date)}
										rightText={workout.workout_name}
										expanded={showItem.includes(toggleKey)}
										callbackToggle={() => toggleHandler(toggleKey)}
										fontColor="var(--Baby)"
									/>
									<ExpandingCardContent expanded={showItem.includes(toggleKey)}>
										<div className="table-wrapper">
											<div className="table-header">
												<p>Set</p>
												<p>Excercise</p>
												<p>Metrics</p>
											</div>
											<div className="table-content">
												{workout.exercise_results &&
													workout.exercise_results.map((exercise, index) => {
														console.log(exercise);
														const backgroundColor =
															exercise.exercise_order % 2 === 0
																? "var(--Teal)"
																: "var(--Tiffany)";
														return (
															<div className="table-item" key={exercise.id}>
																<div
																	className="table-grid-item"
																	style={{
																		backgroundColor: backgroundColor,
																	}}
																>
																	<p>{exercise.exercise_set}</p>
																</div>
																<div
																	className="table-grid-item"
																	style={{
																		backgroundColor: backgroundColor,
																	}}
																>
																	<p>{exercise.exercise_name}</p>
																</div>
																<div
																	className="table-grid-item-history"
																	style={{
																		backgroundColor: backgroundColor,
																	}}
																>
																	{exercise.reps && <p>- Reps: {exercise.reps} </p>}
																	{exercise.distance && (
																		<p>
																			- Distance: {exercise.distance}{" "}
																			<span style={{ color: "var(--Gray)" }}>
																				({exercise.distance_unit})
																			</span>
																		</p>
																	)}
																	{exercise.time && (
																		<p>
																			- Time: {exercise.time}{" "}
																			<span style={{ color: "var(--Gray)" }}>
																				(seconds)
																			</span>
																		</p>
																	)}
																	{exercise.weight && (
																		<p>
																			- Weight: {exercise.weight}{" "}
																			<span style={{ color: "var(--Gray)" }}>
																				({exercise.weight_unit})
																			</span>
																		</p>
																	)}
																</div>
															</div>
														);
													})}
											</div>
										</div>
										{/* {workout.exercise_results &&
											workout.exercise_results.map((exercise) => {
												return (
													<div className="history-card-excercise-group" key={exercise.id}>
														<div className="history-card-excercise-header">
															<h3>Set #{exercise.exercise_set} </h3>
															<h3> {exercise.exercise_name}</h3>
														</div>
														{exercise.reps && (
															<div className="history-card-input">
																<h4>Reps: {exercise.reps}</h4>
															</div>
														)}
														{exercise.distance && (
															<div className="history-card-input">
																<h4>
																	Distance: {exercise.distance}{" "}
																	<span>({exercise.distance_unit})</span>
																</h4>
															</div>
														)}
														{exercise.time && (
															<div className="history-card-input">
																<h4>
																	Time: {exercise.time} <span>(seconds)</span>
																</h4>
															</div>
														)}
														{exercise.weight && (
															<div className="history-card-input">
																<h4>
																	Weight: {exercise.weight}{" "}
																	<span>({exercise.weight_unit})</span>
																</h4>
															</div>
														)}
													</div>
												);
											})} */}
										<h3
											className="delete-workout"
											onClick={() => {
												setShowDeleteModal(true);
												setWorkoutToDelete(workout);
											}}
										>
											Delete This Workout
										</h3>
									</ExpandingCardContent>
								</ExpandingCard>
							);
						})}
				</FlexGroup>
				<Spacer height="1rem" />
			</MainLayout>
			{showDeleteModal && (
				<Modal setShowModal={setShowDeleteModal}>
					<h2>Are you sure you want to delete this workout?</h2>
					<Spacer height="1rem" />
					<h3>{formateDate(workoutToDelete.workout_date)}</h3>
					<h3>{workoutToDelete.workout_name}</h3>
					<Spacer height="1rem" />
					<FlexGroup width="100%" center="center">
						<PostMessage
							successMessage="Workout successfully deleted! Refreshing your history in 3 seconds..."
							errorMessage="Error deleting workout. Please try again."
							status={deleteWorkoutMutation.status}
						>
							<Button
								text="Delete"
								onClickCallback={() => handleDeleteWorkout(workoutToDelete.submission_id)}
							/>
						</PostMessage>
					</FlexGroup>
				</Modal>
			)}
		</Query>
	);
}
