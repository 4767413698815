import ExpandingCardContent from "../ExpandingCard/ExpandingCardContent";
import ExpandingCardHeader from "../ExpandingCard/ExpandingCardHeader";
import ExpandingCard from "../ExpandingCard/ExpandingCard";
import FlexGroup from "../FlexGroup/FlexGroup";
import { useState } from "react";
import "./Select.css";

export default function Select({ options, selected, setSelected }) {
	const [opened, setOpened] = useState(false);

	function toggleSelectCard() {
		setOpened(!opened);
	}

	function handleSelect(id) {
		if (id === selected) {
			setSelected(null);
			return;
		}
		setSelected(id);
	}

	return (
		<div className="select-container">
			<FlexGroup gap="10px">
				<ExpandingCard background="var(--Baby)">
					<ExpandingCardHeader
						leftText="Select..."
						rightText=""
						expanded={opened}
						callbackToggle={toggleSelectCard}
					/>
					<ExpandingCardContent expanded={opened}>
						<div className="select-option-group">
							{options &&
								options.length > 0 &&
								options.map((option, index) => {
									return (
										<div className="select-option" key={index}>
											<h4
												onClick={() => handleSelect(option)}
												style={{
													backgroundColor:
														selected === option ? "var(--Teal)" : "var(--Baby)",
												}}
											>
												{option.name}
											</h4>
										</div>
									);
								})}
						</div>
					</ExpandingCardContent>
				</ExpandingCard>
			</FlexGroup>
		</div>
	);
}
