import CommunityModalTable from "./CommunityModalTable";
import Spacer from "../Spacer/Spacer";
import Modal from "../Modal/Modal";
import ReactQuill from "react-quill";

export default function CommunityModal({ setShowModal, workoutForModal, modalData }) {
	return (
		<Modal setShowModal={setShowModal}>
			<div className="community-modal">
				<h2>@{workoutForModal.user.displayName}</h2>
				<h3>{workoutForModal.name}</h3>
				<ReactQuill value={workoutForModal.description} readOnly={true} modules={{ toolbar: false }} />
				<CommunityModalTable modalData={modalData} />
				<Spacer />
			</div>
		</Modal>
	);
}
