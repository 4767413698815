import React, { useState } from "react";
import "./Button.css";

export default function Button({ text, onClickCallback }) {
	const [isHovering, setIsHovering] = useState(false);

	let primaryColor = "var(--Teal)";
	let secondaryColor = "var(--Tiffany)";

	return (
		<button
			className={`${isHovering ? "btn-shift" : "btn-static"} duro-btn`}
			onMouseEnter={() => {
				setIsHovering(true);
			}}
			onMouseLeave={() => {
				setIsHovering(false);
			}}
			onClick={onClickCallback}
		>
			<span className="btn-pri" style={{ backgroundColor: primaryColor }}>
				{text}
			</span>
			<span className="btn-sec" style={{ backgroundColor: secondaryColor }}>
				{text}
			</span>
		</button>
	);
}
