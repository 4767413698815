import { Figura, FiguraSubmitBtn, FiguraLabel, FiguraPassword } from "react-figura";
import PostMessage from "../PostMessage/PostMessage";
import FlexGroup from "../FlexGroup/FlexGroup";
import { updatePassword } from "firebase/auth";
import Spacer from "../Spacer/Spacer";
import Modal from "../Modal/Modal";
import { useState } from "react";

export default function UpdatePassword({ setShowUpdatePassword, user }) {
	const [status, setStatus] = useState("idle");
	const [errorMessage, setErrorMessage] = useState("Error updating password. Please try again or check back later.");

	async function handleUpdatingPassword({ password }) {
		setStatus("loading");
		if (user) {
			updatePassword(user, password)
				.then(() => {
					setStatus("success");
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				})
				.catch((error) => {
					if (error.code === "auth/requires-recent-login") {
						setErrorMessage(
							"You have not logged in for awhile, please log out and back in to change your password."
						);
					}
					setStatus("error");
					console.error("Error updating password:", error);
				});
		} else {
			setStatus("error");
		}
	}

	return (
		<Modal setShowModal={setShowUpdatePassword}>
			<h2 className="modal-title">Update Password</h2>
			<Figura formId="update-password" formStyle="profile-figura-form" onSubmit={handleUpdatingPassword}>
				<FiguraPassword
					name="password"
					inputStyle="figura-input"
					errorStyle="figura-error"
					wrapper="figura-wrapper"
				>
					<FiguraLabel labelStyle="figura-label">New Password</FiguraLabel>
				</FiguraPassword>
				<FiguraSubmitBtn buttonStyle="figura-submit">Submit</FiguraSubmitBtn>
			</Figura>
			<Spacer height="1rem" />
			<FlexGroup width="100%" center="center">
				<PostMessage
					successMessage="Password updated! Refreshing in 3 seconds..."
					errorMessage={errorMessage}
					status={status}
				></PostMessage>
			</FlexGroup>
			<Spacer height="1rem" />
		</Modal>
	);
}
