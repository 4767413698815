import { useEffect, useState } from "react";
import { getWorkoutExercises } from "../Api/Workouts";
import { useQuery } from "react-query";

export default function useCommunityModal(token) {
	const [showModal, setShowModal] = useState(false);
	const [workoutForModal, setWorkoutForModal] = useState(null);
	const [modalData, setModalData] = useState(null);

	const { refetch: workoutForModalRefetch } = useQuery(
		"workoutForModal",
		() => getWorkoutExercises(workoutForModal.id, token),
		{
			enabled: false,
			staleTime: 0,
			onSuccess: (data) => {
				setModalData(data);
			},
		}
	);

	useEffect(() => {
		if (workoutForModal) workoutForModalRefetch();
		// eslint-disable-next-line
	}, [workoutForModal]);

	function setupModal(workout) {
		setWorkoutForModal(workout);
		setShowModal(true);
	}

	return {
		modalData,
		showModal,
		workoutForModal,
		setupModal,
		setShowModal,
	};
}
