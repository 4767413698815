import { useLocation, useNavigate } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import { auth } from "../Auth/Firebase";
import { useEffect } from "react";

export default function useEmailVerification(emailVerified) {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (emailVerified) return;

		const urlParams = new URLSearchParams(location.search);
		const actionCode = urlParams.get("oobCode");

		if (!actionCode) {
			console.error("No action code found.");
			return;
		}

		applyActionCode(auth, actionCode)
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				console.error("Error applying action code: ", error);
			});
	}, [location, navigate, emailVerified]);
}
