import CommunityMessage from "../../Components/Community/CommunityMessage";
import CommunityModal from "../../Components/Community/CommunityModal";
import CommunityPost from "../../Components/Community/CommunityPost";
import useCommunityModal from "../../Hooks/CommunityModalHook";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import useCommunity from "../../Hooks/CommunityHook";
import usePagination from "../../Hooks/PaginationHook";
import NavBar from "../../Components/NavBar/NavBar";
import Search from "../../Components/Search/Search";
import Spacer from "../../Components/Spacer/Spacer";
import Query from "../../Components/Query/Query";
import Modal from "../../Components/Modal/Modal";
import { searchUsers } from "../../Api/Community";
import { useNavigate } from "react-router-dom";
import useSocial from "../../Hooks/SocialHook";
import { debounce } from "../../Utils/helpers";
import useAuth from "../../Hooks/AuthHook";
import { useQuery } from "react-query";
import React, { useState } from "react";
import "./Community.css";

export default function Community() {
	/* States */
	const [selected, setSelected] = useState("Popular");
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);

	/* Hooks */
	const navigate = useNavigate();
	const { uid, token } = useAuth("/login");
	const P = usePagination();
	const C = useCommunity(uid, token, selected, P.currentPage);
	const S = useSocial(C.community, C.setCommunity, uid, token);
	const M = useCommunityModal(token);

	/* Querys */
	const { refetch: searchRefetch } = useQuery("searchUsers", () => searchUsers(query, uid, token), {
		enabled: false,
		onSuccess: (data) => {
			C.setCommunity(data);
			setLoading(false);
		},
	});

	/* Functions */
	function goPersonal(id) {
		navigate(`/community/${id}`);
	}

	const debouncedSearch = debounce(800, () => searchRefetch());

	function searchFunction(query) {
		setLoading(true);
		setQuery(query);
		debouncedSearch();
	}

	function updateSelected(column) {
		C.setReachedEnd(false);
		P.setCurrentPage(1);
		C.setCommunity([]);
		setSelected(column);
	}

	/* Render */
	return (
		<Query
			data={C.community}
			status={C.determineStatus()}
			errorMessage="We encountered an issue retrieving community workouts. Please try again or check back later."
		>
			{loading && <div className="spinner" />}
			<MainLayout>
				<Spacer />
				<FlexGroup>
					<NavBar
						selected={selected}
						setSelected={updateSelected}
						fontSize="12px"
						columns={["Popular", "Recent", "Following", "Liked", "Search"]}
					/>
				</FlexGroup>
				{selected === "Search" && (
					<Search searchFunction={searchFunction} placeholder="Search..." fontSize="16px" expand={false} />
				)}
				<div className="community-feed">
					<CommunityMessage community={C.community} selected={selected} />
					{C.community &&
						Array.isArray(C.community) &&
						C.community.length > 0 &&
						C.community.map((post, index) => {
							return (
								<CommunityPost
									personal={false}
									key={index}
									post={post}
									index={index}
									setupModal={M.setupModal}
									follow={S.follow}
									likes={S.likes}
									goPersonal={goPersonal}
									thisRef={
										C.community.length - index === 8 && C.community.length >= 50
											? P.nextPageElement
											: null
									}
								/>
							);
						})}
				</div>
			</MainLayout>
			{M.showModal && (
				<CommunityModal
					setShowModal={M.setShowModal}
					workoutForModal={M.workoutForModal}
					modalData={M.modalData}
				/>
			)}
			{S.showError && (
				<Modal setShowModal={S.setShowError}>
					<h2>Error:</h2>
					<h3 style={{ color: "var(--Red)" }}>{S.errorMessage}</h3>
				</Modal>
			)}
		</Query>
	);
}
