import ExpandingCardContent from "../../Components/ExpandingCard/ExpandingCardContent";
import ExpandingCardHeader from "../../Components/ExpandingCard/ExpandingCardHeader";
import ExpandingCard from "../../Components/ExpandingCard/ExpandingCard";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import useToggler from "../../Hooks/ToggleHook";
import { useNavigate } from "react-router-dom";
import { faq } from "../../Utils/faq";

export default function Support() {
	const navigate = useNavigate();
	const { showItem, toggleHandler } = useToggler([]);

	function goReportProblems() {
		navigate("/support/problem");
	}

	function goGiveFeedback() {
		navigate("/support/feedback");
	}

	return (
		<MainLayout>
			<Spacer height="1rem" />
			<h1>Support</h1>
			<FlexGroup>
				<Button text="Report Problems" onClickCallback={goReportProblems} />
				<Button text="Give Feedback" onClickCallback={goGiveFeedback} />
			</FlexGroup>
			<h2 style={{ fontSize: "24px" }}>FAQ</h2>
			<FlexGroup gap="10px">
				{faq.map((item, index) => {
					return (
						<ExpandingCard key={index}>
							<ExpandingCardHeader
								leftText={item.question}
								expanded={showItem.includes(index)}
								callbackToggle={() => toggleHandler(index)}
							/>
							<ExpandingCardContent expanded={showItem.includes(index)}>
								<p style={{ color: "var(--Black" }}>{item.answer}</p>
							</ExpandingCardContent>
						</ExpandingCard>
					);
				})}
			</FlexGroup>
			<Spacer height="1rem" />
		</MainLayout>
	);
}
