import { HiOutlineX } from "react-icons/hi";
import "./Modal.css";
import { useEffect } from "react";

export default function Modal({ setShowModal, children }) {
	function closeModal() {
		setShowModal(false);
	}

	useEffect(() => {
		document.getElementById("wrapper").classList.add("blur");
		return () => {
			document.getElementById("wrapper").classList.remove("blur");
		};
	}, []);

	return (
		<div className="modal-cover">
			<div className="modal-container modal-scrollable">
				<HiOutlineX size="30px" className="modal-close-icon" onClick={closeModal} />
				{children}
			</div>
		</div>
	);
}
