export const faq = [
	{
		question: "How do I start a workout?",
		answer: `Click the Start Workout button on the home page. 
        Then select a workout from the list and click the start button.`,
	},
	{
		question: "How do I create a workout?",
		answer: `Click the Create Workout button on the home page. 
        Then fill out the form and click the create button.`,
	},
	{
		question: "How do I view my workout history?",
		answer: `Click the Workout History button on the home page. 
        Then select a workout from the list and click the view button.`,
	},
	{
		question: "How do I view my workout history?",
		answer: `Click the Workout History button on the home page. 
        Then select a workout from the list and click the view button.`,
	},
	{
		question: "How do I view my workout history?",
		answer: `Click the Workout History button on the home page. 
        Then select a workout from the list and click the view button.`,
	},
	{
		question: "How do I view my workout history?",
		answer: `Click the Workout History button on the home page. 
        Then select a workout from the list and click the view button.`,
	},
];
