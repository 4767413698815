import "./StartMessage.css";

export default function StartMessage({ selected }) {
	return (
		<div className="start-message">
			{selected === "Default" && <p>Choose a workout from workouts that duro provides by default.</p>}
			{selected === "Yours" && <p>Choose a workout from workouts that you created.</p>}
			{selected === "Liked" && <p>Choose a workout from workouts that you have liked within the community.</p>}
		</div>
	);
}
