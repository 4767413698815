import "./NumInput.css";

export default function NumInput({ title, metricUnit, onChangeCallback, callbackParams }) {
	function callbackSetNum(e) {
		e.preventDefault();
		onChangeCallback(e.target.value, callbackParams);
	}

	return (
		<div className="num-input-wrapper">
			<h4>
				{title} {metricUnit && <span>({metricUnit})</span>}
			</h4>
			<input
				type="number"
				name={`${title}`}
				className="num-input"
				min="0"
				max="999999"
				onChange={(e) => {
					let value = parseInt(e.target.value, 10);
					if (isNaN(value)) {
						e.target.value = "";
					} else {
						value = Math.max(0, Math.min(999999, value));
						e.target.value = value.toString();
					}
					callbackSetNum(e);
				}}
			/>
		</div>
	);
}
