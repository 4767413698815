import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import NavBar from "../../Components/NavBar/NavBar";
import Spacer from "../../Components/Spacer/Spacer";
import Search from "../../Components/Search/Search";
import Query from "../../Components/Query/Query";
import { getFeedback } from "../../Api/Community";
import { formateDate } from "../../Utils/helpers";
import useAuth from "../../Hooks/AuthHook";
import { useQuery } from "react-query";
import ReactQuill from "react-quill";
import { useState } from "react";
import "./Admin.css";

export default function Admin() {
	/* States */
	const [selectedFeedbackType, setSelectedFeedbackType] = useState("Both");
	const [selectedContactType, setSelectedContactType] = useState("Both");
	const [feedbackData, setFeedbackData] = useState([]);

	/* Hooks */
	const { uid, token } = useAuth("/login");

	/* Querys */
	const { data, status } = useQuery("admin", () => getFeedback(uid, token), {
		onSuccess: (data) => {
			if (!data || data.error) return;
			setFeedbackData(data);
		},
		enabled: !!token,
	});

	/* Functions */
	function applyFilters(data) {
		const filtered = data.filter((item) => {
			if (selectedFeedbackType === "Feedback") {
				return item.type === "feedback";
			} else if (selectedFeedbackType === "Problem") {
				return item.type === "problem";
			} else {
				return item;
			}
		});
		const filtered2 = filtered.filter((item) => {
			if (selectedContactType === "Can Contact") {
				return item.can_contact === true;
			} else if (selectedContactType === "Cannot Contact") {
				return item.can_contact === false;
			} else {
				return item;
			}
		});
		return filtered2;
	}

	function handleSearch(query) {
		const searched = data.filter((item) => {
			return (
				item.userDetails.displayName.toLowerCase().includes(query) ||
				item.userDetails.email.toLowerCase().includes(query) ||
				formateDate(item.created_at).includes(query)
			);
		});
		setFeedbackData(searched);
	}

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We encountered an issue. Please try again or check back later."
		>
			<MainLayout>
				<Spacer height="1rem" />
				<h1>Admin</h1>
				<Search
					searchFunction={handleSearch}
					expand={false}
					placeholder="Search by username, email, or date..."
				/>
				<FlexGroup gap="10px">
					<NavBar
						selected={selectedFeedbackType}
						setSelected={setSelectedFeedbackType}
						columns={["Both", "Feedback", "Problem"]}
					/>
					<NavBar
						selected={selectedContactType}
						setSelected={setSelectedContactType}
						columns={["Both", "Can Contact", "Cannot Contact"]}
					/>
				</FlexGroup>
				<FlexGroup gap="10px">
					{feedbackData &&
						applyFilters(feedbackData).map((item, index) => {
							return (
								<div key={index} className="feedback-card">
									<h3>{item.userDetails.displayName}</h3>
									<h3>{item.userDetails.email}</h3>
									<h3>{formateDate(item.created_at)}</h3>
									<h3>Type: {item.type}</h3>
									<Spacer height="1rem" />
									<ReactQuill value={item.feedback} readOnly={true} modules={{ toolbar: false }} />
									<Spacer height="1rem" />
									<h4 style={{ color: item.can_contact ? "var(--Green)" : "var(--Red)" }}>
										{item.can_contact ? "Can Contact" : "Cannot Contact"}
									</h4>
								</div>
							);
						})}
				</FlexGroup>
				<Spacer height="1rem" />
			</MainLayout>
		</Query>
	);
}
