import ScrollUp from "../../Components/ScrollUp/ScrollUp";
import { useRef, useState } from "react";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import "./MainLayout.css";

export default function MainLayout({ children }) {
	const [showMenu, setShowMenu] = useState(false);
	const scrollContainerRef = useRef(null);

	return (
		<div id="wrapper" className="wrapper">
			<Menu showMenu={showMenu} setShowMenu={setShowMenu} />
			<Header showMenu={showMenu} setShowMenu={setShowMenu} />
			<div className={`${showMenu ? "blur" : ""} layout-container scrollable`} ref={scrollContainerRef}>
				{children}
				<ScrollUp scrollContainerRef={scrollContainerRef} />
			</div>
		</div>
	);
}
