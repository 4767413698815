import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import termsAndConditions from "./TermsAndConditions.json";
import NavBar from "../../Components/NavBar/NavBar";
import Spacer from "../../Components/Spacer/Spacer";
import privacyPolicy from "./PrivacyPolicy.json";
import ReactQuill from "react-quill";
import { useState } from "react";
import "./Legal.css";

export default function Legal() {
	const [selected, setSelected] = useState("Privacy Policy");

	return (
		<MainLayout>
			<Spacer />
			<h2>Legal</h2>
			<FlexGroup>
				<NavBar
					selected={selected}
					setSelected={setSelected}
					columns={["Privacy Policy", "Terms & Conditions"]}
				/>
			</FlexGroup>
			<ReactQuill
				value={selected === "Privacy Policy" ? privacyPolicy : termsAndConditions}
				readOnly={true}
				modules={{ toolbar: false }}
			/>
		</MainLayout>
	);
}
