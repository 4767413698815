import StartMessage from "../../Components/Start/StartMessage";
import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import NavBar from "../../Components/NavBar/NavBar";
import Search from "../../Components/Search/Search";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import Select from "../../Components/Select/Select";
import Query from "../../Components/Query/Query";
import { getWorkouts } from "../../Api/Workouts";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/AuthHook";
import { useQuery } from "react-query";
import { useState } from "react";
import "./Start.css";

export default function Start() {
	/* States */
	const [selected, setSelected] = useState("Default");
	const [selectedWorkout, setSelectedWorkout] = useState(null);

	/* Hooks */
	const navigate = useNavigate();
	const { isVerified, token, uid } = useAuth("/login");

	/* Querys */
	const { data, status } = useQuery(["getWorkouts", selected], () => getWorkouts(selected, uid, token), {
		enabled: !!token,
	});

	/* Functions */
	function goLogin() {
		navigate("/login");
	}

	function goWorkout() {
		if (!selectedWorkout) return;
		localStorage.setItem("selectedWorkout", selectedWorkout.id);
		navigate("/submission");
	}

	function searchFunction(query) {
		return data.filter((item) => {
			return item.name.toLowerCase().includes(query);
		});
	}

	/* Render */
	return (
		<Query
			data={data}
			status={status}
			errorMessage="We encountered an issue retrieving workouts. Please try again or check back later."
		>
			<MainLayout>
				<Spacer height="1rem" />
				<h2>Choose Workout</h2>
				<FlexGroup gap="10px">
					<NavBar selected={selected} setSelected={setSelected} columns={["Default", "Yours", "Liked"]} />
					<StartMessage selected={selected} />
				</FlexGroup>
				{selected === "Yours" && !isVerified ? (
					<div className="start-notice">
						<p>You must be logged in to use the Custom Workouts feature. </p>
						<p>
							<span onClick={goLogin}>Click Here</span> to Login/ Create An Account.
						</p>
					</div>
				) : (
					<>
						<Search
							searchFunction={searchFunction}
							selected={selectedWorkout}
							setSelected={setSelectedWorkout}
							expand={true}
						/>
						<Select options={data} selected={selectedWorkout} setSelected={setSelectedWorkout} />
						<FlexGroup>
							<Button text="Start" onClickCallback={goWorkout} />
						</FlexGroup>
						<Spacer height="1rem" />
					</>
				)}
			</MainLayout>
		</Query>
	);
}
