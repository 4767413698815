import Loader from "../Loader/Loader";
import Error from "../Error/Error";

export default function Query({ children, data, status, errorMessage }) {
	const defaultErrorMessage =
		"We couldn't find the data that we needed. Try refreshing the page or check back later.";

	if (status === "loading") {
		return <Loader />;
	} else if (status === "error" || (data && data.error)) {
		return <Error dataError={data.error} message={errorMessage} />;
	} else if (status === "success") {
		return children;
	} else {
		return <Error message={defaultErrorMessage} />;
	}
}
