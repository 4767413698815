import { useState } from "react";

export default function useCreateValidation() {
	const [error, setError] = useState([]);

	const descriptionOverLimit = (delta) => {
		let charCount = 0;
		if (!delta || typeof delta === "object" || Object.keys(delta).length === 0) return false;
		delta.ops.forEach((op) => {
			if (typeof op.insert === "string") {
				charCount += op.insert.length;
			}
		});
		if (charCount > 1000) {
			return true;
		} else {
			return false;
		}
	};

	function handleValidatingWorkout(tempWorkout) {
		let validWorkout = true;
		let tempError = [];
		if (tempWorkout.workoutName === "") {
			tempError.push("Please enter a workout name.");
			validWorkout = false;
		}
		if (tempWorkout.workoutName.length > 32) {
			tempError.push("Workout name must be less than 32 characters.");
			validWorkout = false;
		}
		if (Object.keys(tempWorkout.workoutDescription).length === 0) {
			tempError.push("Please enter a workout description.");
			validWorkout = false;
		}
		if (descriptionOverLimit(tempWorkout.workoutDescription)) {
			tempError.push("Workout description must be less than 1000 characters.");
			validWorkout = false;
		}
		if (tempWorkout.exercises.length === 0) {
			tempError.push("Please add at least one exercise.");
			validWorkout = false;
		}
		if (tempWorkout.exercises.length > 20) {
			tempError.push("A Workout must have less than 20 exercises.");
			validWorkout = false;
		}
		setError(tempError);
		return validWorkout;
	}

	return { error, handleValidatingWorkout, descriptionOverLimit };
}
