import CommunityModal from "../../../Components/Community/CommunityModal";
import CommunityPost from "../../../Components/Community/CommunityPost";
import useCommunityModal from "../../../Hooks/CommunityModalHook";
import FlexGroup from "../../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../../Layouts/MainLayout/MainLayout";
import { HiArrowSmallLeft, HiUserPlus } from "react-icons/hi2";
import Spacer from "../../../Components/Spacer/Spacer";
import { userWorkouts } from "../../../Api/Community";
import Query from "../../../Components/Query/Query";
import useSocial from "../../../Hooks/SocialHook";
import { useParams } from "react-router-dom";
import useAuth from "../../../Hooks/AuthHook";
import { useQuery } from "react-query";
import { useState } from "react";

export default function Personal() {
	const [community, setCommunity] = useState();

	const params = useParams();
	const { uid, token } = useAuth("/login");
	const S = useSocial(community, setCommunity, uid, token);
	const M = useCommunityModal(token);

	const isEnabled = !!uid && !!token && !!params.id;
	const { data: userData, status: userStatus } = useQuery(
		"popularWorkouts",
		() => userWorkouts(uid, params.id, token),
		{
			onSuccess: (data) => {
				setCommunity(data);
			},
			enabled: isEnabled,
		}
	);

	return (
		<Query
			data={userData}
			status={userStatus}
			errorMessage="We encountered an issue retrieving this users workouts. Please try again or check back later."
		>
			{community && (
				<MainLayout>
					<Spacer />
					<FlexGroup direction={"row"} center={"center"}>
						<HiArrowSmallLeft
							className="personal-svg"
							onClick={() => window.history.back()}
							style={{ cursor: "pointer", width: "40px", height: "40px", color: "var(--Baby)" }}
						/>
						<h2>{community[0].user.displayName}</h2>
						<HiUserPlus
							className="personal-svg"
							onClick={() => S.follow(community[0].userFollowsCreator, community[0].user.id)}
							style={{
								cursor: "pointer",
								width: "30px",
								height: "30px",
								color: `${community[0].userFollowsCreator ? "var(--Green)" : "var(--Gray)"}`,
								filter: `${
									community[0].userFollowsCreator ? "drop-shadow(0px 0px 4px var(--Green))" : "none"
								}`,
							}}
						/>
					</FlexGroup>
					<div className="community-feed">
						{community &&
							Array.isArray(community) &&
							community.length > 0 &&
							community.map((post, index) => {
								return (
									<CommunityPost
										personal={true}
										key={index}
										post={post}
										index={index}
										setupModal={M.setupModal}
										follow={S.follow}
										likes={S.likes}
									/>
								);
							})}
					</div>
				</MainLayout>
			)}
			{M.showModal && (
				<CommunityModal
					setShowModal={M.setShowModal}
					workoutForModal={M.workoutForModal}
					modalData={M.modalData}
				/>
			)}
		</Query>
	);
}
