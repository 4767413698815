import { AiOutlineCloseCircle, AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai";
import useCreateTable from "../../Hooks/CreateTableHook";
import { useEffect, useState } from "react";

export default function CreateTable({ tempWorkout, setTempWorkout }) {
	/* States */
	const [flash, setFlash] = useState(false);

	/* Hooks */
	const { removeExercise, moveUp, moveDown } = useCreateTable();

	/* Functions */
	useEffect(() => {
		if (flash) {
			const timeout = setTimeout(() => setFlash(false), 300);
			return () => clearTimeout(timeout);
		}
	}, [flash]);

	function handleMoveUp(exercise) {
		moveUp(exercise, setTempWorkout);
		setFlash(true);
	}

	function handleMoveDown(exercise) {
		moveDown(exercise, setTempWorkout);
		setFlash(true);
	}

	function handleRemoveExercise(exercise) {
		removeExercise(exercise, tempWorkout, setTempWorkout);
		setFlash(true);
	}

	/* Render */
	return (
		<>
			{tempWorkout.exercises && tempWorkout.exercises.length > 0 && (
				<div className={`table-wrapper ${flash ? "table-flash" : ""}`}>
					<div className="table-header">
						<p># of sets</p>
						<p>Exercise</p>
						<p>&nbsp;</p>
					</div>
					<div className="table-content">
						{tempWorkout.exercises
							.sort((a, b) => a.order - b.order)
							.map((exercise, index) => {
								const backgroundColor = index % 2 === 0 ? "var(--Teal)" : "var(--Tiffany)";
								return (
									<div className="table-item" key={index}>
										<div className="table-grid-item" style={{ backgroundColor: backgroundColor }}>
											<p>{exercise.sets}</p>
										</div>
										<div className="table-grid-item" style={{ backgroundColor: backgroundColor }}>
											<p>{exercise.name}</p>
										</div>
										<div
											className="table-grid-item-remove"
											style={{ backgroundColor: backgroundColor }}
										>
											<AiOutlineUpCircle onClick={() => handleMoveUp(exercise)} />
											<AiOutlineDownCircle onClick={() => handleMoveDown(exercise)} />
											<AiOutlineCloseCircle onClick={() => handleRemoveExercise(exercise)} />
										</div>
									</div>
								);
							})}
					</div>
				</div>
			)}
		</>
	);
}
