import { Figura, FiguraEmail, FiguraHidden, FiguraLabel, FiguraSubmitBtn, FiguraTitle } from "react-figura";
import MainLayout from "../Layouts/MainLayout/MainLayout";
import FlexGroup from "../Components/FlexGroup/FlexGroup";
import { sendPasswordResetEmail } from "firebase/auth";
import Spacer from "../Components/Spacer/Spacer";
import { useNavigate } from "react-router-dom";
import { auth } from "./Firebase";
import { useState } from "react";

export default function ForgotPwd() {
	const navigate = useNavigate();
	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState({ bool: false, message: "" });
	const goLogin = () => {
		navigate("/login");
	};

	async function handleForgotPassword({ email }) {
		setShowFailure({ bool: false, message: "" });
		setShowSuccess(false);
		try {
			await sendPasswordResetEmail(auth, email).then(() => {
				setShowSuccess(true);
			});
		} catch (error) {
			setShowFailure({ bool: true, message: "Error sending password reset email" });
		}
	}

	return (
		<MainLayout>
			<FlexGroup width="100%" center="center">
				<Spacer height="1rem" />
				<Figura formId="forgot" formStyle="figura-form" onSubmit={handleForgotPassword}>
					<FiguraTitle titleStyle="figura-title">Forgot Password</FiguraTitle>

					<FiguraEmail
						name="email"
						inputStyle="figura-input"
						errorStyle="figura-error"
						wrapper="figura-wrapper"
					>
						<FiguraLabel labelStyle="figura-label">Email:</FiguraLabel>
					</FiguraEmail>

					<FiguraHidden />

					<FiguraSubmitBtn buttonStyle="figura-submit">Submit</FiguraSubmitBtn>
				</Figura>

				{showFailure.bool && <p className="not-verified ver-left">{showFailure.message}</p>}
				{showSuccess && <p className="verified ver-left">We sent you an email with further instructions.</p>}

				<p className="lower-message">
					To reset your password provide your email above and we will send you an email with reset
					instructions.
				</p>

				<button onClick={goLogin} className="figura-button">
					Back To Login
				</button>
			</FlexGroup>
		</MainLayout>
	);
}
