import { useState } from "react";

export default function useToggler(initialState) {
	const [showItem, setShowItem] = useState(initialState);

	function toggleHandler(id) {
		if (showItem.includes(id)) {
			setShowItem(
				showItem.filter(function (newShow) {
					return newShow !== id;
				})
			);
		} else {
			setShowItem((showItem) => [...showItem, id]);
		}
	}

	return { showItem, toggleHandler };
}
