import FlexGroup from "../../Components/FlexGroup/FlexGroup";
import MainLayout from "../../Layouts/MainLayout/MainLayout";
import Spacer from "../../Components/Spacer/Spacer";
import Button from "../../Components/Button/Button";
import Waves from "../../Components/Waves/Waves";
import { useNavigate } from "react-router-dom";
import "./Home.css";

export default function Home() {
	const navigate = useNavigate();
	const goLink = (link) => navigate(link);

	return (
		<MainLayout>
			<Spacer height="1rem" />
			<FlexGroup gap="3rem">
				<Button text="Start Workout" onClickCallback={() => goLink("/start")} />
				<Button text="Create Workout" onClickCallback={() => goLink("/create")} />
				<Button text="Workout History" onClickCallback={() => goLink("/history")} />
				<Button text="Community" onClickCallback={() => goLink("/community")} />
			</FlexGroup>
			<Waves />
		</MainLayout>
	);
}
