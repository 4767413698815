import { Figura, FiguraText, FiguraSubmitBtn, FiguraLabel, FiguraPassword } from "react-figura";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import PostMessage from "../PostMessage/PostMessage";
import { deleteAccount } from "../../Api/Community";
import FlexGroup from "../FlexGroup/FlexGroup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import useLogout from "../../Auth/Logout";
import Spacer from "../Spacer/Spacer";
import Modal from "../Modal/Modal";
import { useState } from "react";

export default function DeleteAccount({ setShowDeleteAccount, user, uid, token }) {
	const [status, setStatus] = useState("idle");
	const [errorMessage, setErrorMessage] = useState("Error deleting account. Please try again or check back later.");

	const navigate = useNavigate();
	const logout = useLogout();

	const deleteAccountMutation = useMutation(deleteAccount, {
		onSuccess: (data) => {
			if (data.error || !data) {
				setErrorMessage("Error deleting account. Please try again or check back later.");
				setStatus("error");
			} else {
				setStatus("success");
				setTimeout(() => {
					navigate("/login");
				}, 3000);
			}
		},
		onError: () => {
			setStatus("error");
		},
	});

	function lastChance() {
		if (errorMessage.includes("You have 1 more attempt before you are logged out.")) {
			logout();
			return;
		}
		setErrorMessage("Invalid. You have 1 more attempt before you are logged out.");
		setStatus("error");
	}

	async function handleAccountDeletion({ password, confirmation }) {
		setStatus("loading");
		if (confirmation === "I Agree") {
			const credential = EmailAuthProvider.credential(user.email, password);
			try {
				await reauthenticateWithCredential(user, credential);
				deleteAccountMutation.mutate({ uid, token });
			} catch (error) {
				if (error.code === "auth/invalid-login-credentials") {
					lastChance();
				}
				setStatus("error");
				console.error("Error deleting account:", error);
			}
		} else {
			lastChance();
		}
	}

	return (
		<Modal setShowModal={setShowDeleteAccount}>
			<h2 className="modal-title">Delete Account</h2>
			<Spacer height="1rem" />
			<h2 style={{ color: "var(--Red)" }}>Warning:</h2>
			<h3 style={{ color: "var(--Red)" }}>
				This action is irreversible. All of your data will be deleted. This includes any custom workouts that
				you have created, workout history, and any community data to unclude likes/follows. Please enter your
				password and type "I Agree" to confirm.
			</h3>
			<Figura formId="delete-account" formStyle="profile-figura-form" onSubmit={handleAccountDeletion}>
				<FiguraPassword
					name="password"
					inputStyle="figura-input"
					errorStyle="figura-error"
					wrapper="figura-wrapper"
				>
					<FiguraLabel labelStyle="figura-label">Password</FiguraLabel>
				</FiguraPassword>
				<FiguraText
					name="confirmation"
					inputStyle="figura-input"
					errorStyle="figura-error"
					wrapper="figura-wrapper"
				>
					<FiguraLabel labelStyle="figura-label">Confirmation</FiguraLabel>
				</FiguraText>
				<FiguraSubmitBtn buttonStyle="figura-submit">Delete My Account</FiguraSubmitBtn>
			</Figura>
			<Spacer height="1rem" />
			<FlexGroup width="100%" center="center">
				<PostMessage
					successMessage={
						"Account and all associated data has been deleted. Redirecting to login page in 3 seconds..."
					}
					errorMessage={errorMessage}
					status={status}
				></PostMessage>
			</FlexGroup>
			<Spacer height="1rem" />
		</Modal>
	);
}
