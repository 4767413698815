export default function useCreateTable() {
	function removeExercise(exerciseToRemove, tempWorkout, setTempWorkout) {
		// Filter out the exercise to remove.
		const updatedExercises = tempWorkout.exercises.filter((exercise) => exercise !== exerciseToRemove);

		// Reorder the remaining exercises.
		for (let i = 0; i < updatedExercises.length; i++) {
			updatedExercises[i].order = i + 1;
		}

		// Update the state with the adjusted exercises.
		setTempWorkout((prevState) => ({
			...prevState,
			exercises: updatedExercises,
		}));
	}

	function moveUp(exerciseToMove, setTempWorkout) {
		setTempWorkout((prevState) => {
			const updatedExercises = [...prevState.exercises];
			const index = updatedExercises.findIndex((exercise) => exercise === exerciseToMove);

			if (index <= 0) return prevState; // Return if it's the first item

			// Swap order with the previous item
			[updatedExercises[index].order, updatedExercises[index - 1].order] = [
				updatedExercises[index - 1].order,
				updatedExercises[index].order,
			];

			return {
				...prevState,
				exercises: updatedExercises,
			};
		});
	}

	function moveDown(exerciseToMove, setTempWorkout) {
		setTempWorkout((prevState) => {
			const updatedExercises = [...prevState.exercises];
			const index = updatedExercises.findIndex((exercise) => exercise === exerciseToMove);

			if (index >= updatedExercises.length - 1) return prevState; // Return if it's the last item

			// Swap order with the next item
			[updatedExercises[index].order, updatedExercises[index + 1].order] = [
				updatedExercises[index + 1].order,
				updatedExercises[index].order,
			];

			return {
				...prevState,
				exercises: updatedExercises,
			};
		});
	}

	return { removeExercise, moveUp, moveDown };
}
