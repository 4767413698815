import { HiChevronUp } from "react-icons/hi";
import "./ExpandingCard.css";

export default function ExpandingCardHeader({ leftText, rightText, expanded, callbackToggle, fontColor }) {
	const thisFontColor = fontColor ? fontColor : "var(--Black)";
	return (
		<div className="expanding-card-header" onClick={callbackToggle}>
			<h3 style={{ color: thisFontColor }}>{leftText}</h3>
			<div className="expanding-card-header-right">
				<h3 style={{ color: thisFontColor }}>{rightText}</h3>
				<HiChevronUp className={`${!expanded ? "rotate-chevron" : ""}`} />
			</div>
		</div>
	);
}
