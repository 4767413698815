import Spacer from "../Spacer/Spacer";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { useState } from "react";

export default function CreateModal({
	showModal,
	setShowModal,
	selectedExercise,
	setSelectedExercise,
	tempWorkout,
	setTempWorkout,
}) {
	const sets = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	const [selectedSet, setSelectedSet] = useState(sets[0]);

	function addExercise() {
		const order = tempWorkout.exercises.length + 1;
		const newExercise = {
			id: selectedExercise.id,
			name: selectedExercise.name,
			sets: parseInt(selectedSet),
			order: order,
		};
		setTempWorkout((prevState) => ({
			...prevState,
			exercises: [...prevState.exercises, newExercise],
		}));
	}

	return (
		<>
			{showModal && (
				<Modal setShowModal={setShowModal}>
					<h2>Confirm Exercise</h2>
					<Spacer height="1rem" />
					<h3 style={{ color: "var(--Teal)" }}>{selectedExercise.name}</h3>
					<Spacer height="1rem" />
					<p>How many sets would you like to add for this exercise?</p>
					<Spacer height="1rem" />
					<h3>Sets:</h3>
					<div className="sets-selector">
						{sets.map((set) => {
							const selected = selectedSet === set ? "selected-set" : "not-selected-set";
							return (
								<p key={set} onClick={() => setSelectedSet(set)} className={selected}>
									{set}
								</p>
							);
						})}
					</div>
					<Spacer height="2rem" />
					<Button
						text="Confirm"
						onClickCallback={() => {
							setShowModal(false);
							addExercise();
							setSelectedExercise(null);
						}}
					/>
					<Spacer height="1rem" />
				</Modal>
			)}
		</>
	);
}
